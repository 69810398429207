import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'

// import logo from './logo.svg';
//TODO: is needed this, already loaded
import './../App.css';

import TenantInvoices from './TenantInvoices.jsx'
import Docs from './Docs.jsx'
import TenantNotifications from './TenantNotifications.jsx'
import Temp from './Temp.jsx'
import Voting from './Voting.jsx'
import BuildingFinance from './BuildingFinance.jsx'
import TenantInfo from './TenantInfo/TenantInfo.jsx'
import TenantUtil from './TenantUtil.jsx'

import '@material/react-top-app-bar/dist/top-app-bar.css';
import '@material/react-material-icon/dist/material-icon.css';
import "@material/react-drawer/dist/drawer.css";
import "@material/react-list/dist/list.css"

import TopAppBar, {
    TopAppBarFixedAdjust,
    TopAppBarIcon,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarTitle,
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';

import Drawer, { DrawerAppContent, DrawerContent, DrawerHeader, DrawerTitle } from '@material/react-drawer';
import List, { ListItem, ListItemText } from '@material/react-list';

import { Route, Redirect, Switch  } from "react-router-dom";

import TenantIssues from './TenantIssues'
import { JWT_TENANT } from '../actions/network-manager';
import { withStyles } from '@material-ui/core';

const styles = {
    logo: {
        display: "flex", 
        justifyContent: 'center'
    }
};


const APP = "/tenant"

const Menu = [
    {
        label: "Notifications",
        pathname: APP + "/notifications"
    },
    {
        label: "Activities issues",
        pathname: APP + "/issues"
    },
    {
        label: "Activities voting",
        pathname: APP + "/voting"
    },
    {
        label: "My account",
        pathname: APP + "/finance/invoices"
    },
    {
        label: "Building finance",
        pathname: APP + "/finance/building-invoices"
    },
    {
        label: "Documents Links",
        pathname: APP + "/docs"
    },
    {
        label: "Tenant Info",
        pathname: APP + "/info"
    }

];

class TenantApp extends Component {
    //TODO: move language to global state
    state = { selectedIndex: Menu.findIndex(item=>item.pathname === window.location.pathname), open: false, english: false };

    render() {
        let { t, i18n, unauthenticated, classes } = this.props

        return (
            unauthenticated ?
            <Redirect to="/login" />
            :
            <>
                <Drawer
                    modal
                    // dismissible
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                >
                    <DrawerHeader>
                        <DrawerTitle tag='h1'>
                            Moj sprat
            </DrawerTitle>
                    </DrawerHeader>
                    <DrawerContent>
                        <List singleSelection selectedIndex={this.state.selectedIndex}
                            onClick={e => { }}
                            handleSelect={(selectedIndex) => {
                                if (selectedIndex >= 0 && selectedIndex < Menu.length) {
                                    this.props.history.push(Menu[selectedIndex])
                                    this.setState({ open: false })
                                }
                            }}
                        >
                            {/*
                            Swapped <Link> with history.push() to fix the issue:
                            https://gitlab.coinkernel.com/coin-kernel/concept-front-mojsprat/issues/2 */}

                            {Menu.map((item) => (
                                <ListItem key={item.label}>
                                    <ListItemText primaryText={t(item.label)} />
                                </ListItem>
                            ))}



                            <ListItem onClick={
                                (e) => {
                                    if (this.state.english) {
                                        i18n.changeLanguage("sr")
                                    } else {
                                        i18n.changeLanguage("en")
                                    }
                                    this.setState({
                                        english: !this.state.english,
                                        // open: false
                                    })
                                    e.stopPropagation()
                                }
                            } >

                                <ListItemText primaryText={this.state.english ? 'Srpski' : 'English'} />
                            </ListItem>
                            <ListItem onClick={
                                (e) => {
                                    localStorage.removeItem(JWT_TENANT)
                                    //TODO: User router, clean data
                                    window.location.href = "/login"
                                    e.stopPropagation()
                                }
                            } >
                                <ListItemText primaryText={t('Logout')} />
                            </ListItem>
                        </List>
                    </DrawerContent>
                </Drawer>
                <DrawerAppContent >
                    <TopAppBar >
                        <TopAppBarRow>
                            <TopAppBarSection align='start'>
                                <TopAppBarIcon navIcon tabIndex={0}>
                                    <MaterialIcon hasRipple icon='menu' onClick={() => this.setState({ open: !this.state.open })} />
                                </TopAppBarIcon>
                            </TopAppBarSection>
                            <TopAppBarSection align='center' className={classes.logo}>
                                    <img src={process.env.PUBLIC_URL + '/assets/logo.png'} />
                                </TopAppBarSection>
                            <TopAppBarSection align='end' role='toolbar'>
                                {/* <TopAppBarIcon actionItem tabIndex={0}>
                                    <MaterialIcon
                                        aria-label="print page"
                                        hasRipple
                                        icon='print'
                                        onClick={() => console.log('print')}
                                    />
                                </TopAppBarIcon> */}
                            </TopAppBarSection>
                        </TopAppBarRow>
                    </TopAppBar>
                    <TopAppBarFixedAdjust>
                        <Switch>
                            <Route path={APP + "/notifications"} component={TenantNotifications} />
                            <Route path={APP + "/temp/:index"} component={Temp} />
                            <Route path={APP + "/issues"} component={() => <TenantIssues />} />
                            <Route path={APP + "/voting"} component={() => <Voting />} />
                            <Route path={APP + "/docs"} component={() => <Docs isClientComponent={true} />} />
                            <Route path={APP + "/finance/invoices"} component={() => <TenantInvoices isClientComponent={true} />} />
                            <Route path={APP + "/finance/building-invoices"} component={() => <BuildingFinance />} />
                            <Route path={APP + "/info"} component={() => <TenantInfo />} />
                            <Route path={APP + "/util"} component={() => <TenantUtil />} />
                            <Route>
                                <Redirect to={APP + "/notifications"} />
                            </Route>
                        </Switch>
                    </TopAppBarFixedAdjust>
                </DrawerAppContent>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        unauthenticated: state.unauthenticated
    }
}

export default
    withStyles(styles) (
        withTranslation()(
            connect(mapStateToProps, null)(
                TenantApp)
        )
    );