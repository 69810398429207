import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { Grid } from '@material/react-layout-grid';
// import { Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
// import TextField, { Input } from '@material/react-text-field';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add'
// import DuplicateIcon from '@material-ui/icons/FileCopy'

import { withTranslation } from 'react-i18next'

import BuildingSelector from './BuildingSelector'
import { postNewNotification, deleteNotification, fetchBuildings, putNotification } from './../actions/data-manager'


import { Table, TableHead, TableRow, TableCell, TableBody, TextField, Tooltip, Box } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';

//TODO: consider counter
// import {MDCTextFieldCharacterCounter} from '@material/textfield/character-counter';
// const characterCounter = new MDCTextFieldCharacterCounter(document.querySelector('.mdc-text-field-character-counter'));

const styles = theme => ({
    buttons: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'flex-end'
    },
    cancelButton: {
        width: "100px", 
        marginInlineEnd: "50px"
    },
    confirmButton: {
        width: "100px"
    },
    actionsCell: {
        paddingInlineEnd: "30px"
    },
    editCell: {
        paddingInlineEnd: "0px"
    },
    controls: {
        display: 'flex', 
        alignItems: 'center', 
        marginTop: '3vh'
    },
    addButton: {
        marginLeft: 'auto', 
        padding: 0
    },
    table: {
        paddingInline: 0
    }


})


class NotificationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: ""
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchBuildings())
    }


    handleGenericChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {
        let { t } = this.props
        let building = (this.props.bld||[])[this.props.selectedBuildingIndex] || {}
        let notifications = building.notifications || []
        let { title } = this.state
        let { description } = this.state
        const { classes } = this.props;
        
        return (
            <Container >
                <Box className={classes.controls}>
                    <BuildingSelector />
                    <Box className={classes.addButton}>
                        <Tooltip title={t("Add new notification")} placeholder={"right"} >
                            {/* <Typography variant="body1">Add new notification</Typography> */}
                            <IconButton
                                disabled={Object.keys(building).length == 0}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState({ selected: undefined, title: "", description: "", isNewPrepared: true }, ()=>{
                                        document.getElementsByName("title")[0].focus(); 
                                    })
                                    // document.getElementsByName("title")[0].focus();
                                    // console.log(document.getElementsByName("title"))
                                }}
                            >
                                <AddIcon color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                { Object.keys(building).length !== 0  && <>               
                { (this.state.isNewPrepared || this.state.isEdit) &&
                <>
                <Grid>
                    <TextField
                        name="title"
                        label={t("Title")}
                        fullWidth
                        disabled={!this.state.isNewPrepared && !this.state.isEdit}
                        value={title}
                        onChange={this.handleGenericChange} />
                </Grid>
                <Grid>
                    <TextField
                        name="description"
                        label={t("Description")}
                        multiline
                        rows={3}
                        variant={'outlined'}
                        fullWidth
                        disabled={!this.state.isNewPrepared && !this.state.isEdit}
                        value={description}
                        onChange={this.handleGenericChange} />
                </Grid>
                <Grid className={classes.buttons}>
                    <Button variant="contained" color="primary" disabled={!building.name
                        || (!this.state.isNewPrepared && !this.state.isEdit)}
                        onClick={
                            (e) => {
                                e.preventDefault();
                                this.setState({ isNewPrepared: false, isEdit: false, selected: undefined, title: '', description: '' })
                            }}
                            className={classes.cancelButton}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="contained" color="primary" disabled={!building.name
                        || (!this.state.isNewPrepared && !this.state.isEdit)}
                        onClick={
                            (e) => {
                                e.preventDefault();

                                if (this.state.isNewPrepared) {
                                    this.props.dispatch(postNewNotification(this.props.selectedBuilding, this.state.title, this.state.description,
                                        (err, data) => {
                                            this.setState({ title: "", description: "", isNewPrepared: false, isEdit: false })
                                        }))
                                } else if (this.state.isEdit) {
                                    this.props.dispatch(putNotification(this.props.selectedBuilding, this.state.selected, this.state.title, this.state.description,
                                        (err, data) => {
                                            this.setState({ title: "", description: "", isNewPrepared: false, isEdit: false })
                                        }
                                    ))
                                } else {
                                    alert('assert')
                                }
                            }}
                            className={classes.confirmButton}>
                        {t("OK")}  
                    </Button>     
                </Grid>
                </>
                }
                <Grid className={classes.table}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Title")}</TableCell>
                                <TableCell align="right" className={classes.actionsCell}>{t("Actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifications.map(notification => (
                                <TableRow key={notification._id}
                                    onClick={() => {
                                        this.setState({
                                            selected: notification._id,
                                            description: notification.description,
                                            title: notification.title,
                                            isEdit: false,
                                            isNewPrepared: false
                                        })
                                        
                                    }}
                                    selected={this.state.selected === notification._id}
                                >
                                    <TableCell >{notification.title}</TableCell>
                                    <TableCell align="right" className={classes.editCell}>
                                        {/* <Tooltip title="Kopiraj">
                                            <IconButton
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.setState({
                                                        description: notification.description,
                                                        title: notification.title,
                                                        isNewPrepared: true
                                                    })
                                                }}
                                            >
                                                <DuplicateIcon color={"primary"} />
                                            </IconButton>
                                        </Tooltip> */}
                                        <Tooltip title={t("Edit")}>
                                            <IconButton
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.setState({
                                                        selected: notification._id,
                                                        description: notification.description,
                                                        title: notification.title,
                                                        isEdit: true
                                                    })
                                                    window.scrollTo(0,document.body.scrollHeight)
                                                }}
                                            >
                                                <EditIcon color={"primary"} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Obrisi">
                                            <IconButton onClick={
                                                (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation()
                                                    this.props.dispatch(deleteNotification(this.props.selectedBuilding, notification._id))
                                                    this.setState({ isNewPrepared: false, selected: undefined, title: "", description: "" })
                                                }}>
                                                <DeleteIcon color={"error"} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </Grid>

                </>
                }
            </Container>

        )
    }
}


function mapStateToProps(state) {
    return {
        selectedBuilding: state.selectedBuilding,
        selectedBuildingIndex: state.selectedBuildingIndex,
        bld: state.buildings
    }
}

export default
    withStyles(styles)(
        withTranslation()(
            connect(mapStateToProps, null)(NotificationComponent)));
