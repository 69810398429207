import React, { Component } from "react";
import { connect } from "react-redux";

import Container from '@material-ui/core/Container';
import { Grid } from '@material/react-layout-grid';
import Button from '@material-ui/core/Button';

import BuildingSelector from './BuildingSelector'
import { createDocuments } from './../actions'
import { withTranslation } from "react-i18next";
import { fetchTemplateMeetingVoting, fetchTemplateLatestVoting, fetchBuildings } from './../actions/data-manager'


class TemplateGenetratorComponent extends Component {

    componentDidMount() {
        this.props.fetchBuildings();
    }

    render() {
        let { t } = this.props
        // let { selectedBuilding } = this.props
        return (<div>
                {/* <BuildingSelector>
                </BuildingSelector> */}
                    
                    <Button variant="contained" color="primary" onClick={
                        (e) => {
                            e.preventDefault();
                            this.props.createDocuments({ buildingId: this.props.selectedBuilding })
                        }}>
                        {t("single voting")}
                    </Button>
                    <span style={{ display: "inline-block", width: "5px" }} />
                    <Button variant="contained" color="primary" onClick={
                        (e) => {
                            e.preventDefault();
                            fetchTemplateMeetingVoting(this.props.selectedBuilding)
                        }}>
                        {t("group voting")}
                    </Button>
                    <span style={{ display: "inline-block", width: "5px" }} />
                    <Button variant="contained" color="primary" onClick={
                        (e) => {
                            e.preventDefault();
                            fetchTemplateLatestVoting(this.props.selectedBuilding)
                        }}>
                        {t("voting report")}
                    </Button>
                    </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        selectedBuilding: state.selectedBuilding
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, { createDocuments, fetchBuildings })(
            TemplateGenetratorComponent));

// const TemplateGenetrator = connect(mapStateToProps)(TemplateGenetratorComponent);
// export default TemplateGenetrator;