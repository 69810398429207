import React, { Component } from "react";
import { connect } from "react-redux";
import { genericStateChange } from './../actions'
import { withTranslation } from 'react-i18next'
import { FormControl, MenuItem, Select, InputLabel, Menu }  from '@material-ui/core'
import { withStyles } from "@material-ui/styles";


const styles = theme => ({
    formControl: {
        minWidth: "300px",
    }
});


class UnitSelectorComponent extends Component {
    render() {
        let {t} = this.props
        let comboIndex = (this.props.selectedUnitIndex + 1) || 0
        const { classes, theme } = this.props
        return (
            // <Grid>
            <>
            <FormControl className={classes.formControl}>
                <InputLabel>Izaberite jedinicu</InputLabel>
                <Select
                    value={comboIndex || ""}
                    onChange={(event) => {this.props.genericStateChange("selectedUnitIndex", event.target.value - 1)}}
                    variant="standard"
                    MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                >
                    <MenuItem value={""} key={""}></MenuItem>
                    {
                        this.props.unitNames.map((unit, ix) => (
                            <MenuItem value={ix + 1} key={ix} >{unit.address + " (" + unit.owner.firstName + " " + unit.owner.lastName + ")"}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            </>
            // </Grid>
        )
    }
}

function mapStateToProps(state) {
    let building = (state.buildings || [])[state.selectedBuildingIndex] || {}
    let units = building.units || []
    return {
        selectedUnitIndex: state.selectedUnitIndex,
        //TODO: check if map will rerender
        unitNames: units.map(unit => { return { address: unit.address, owner: unit.owner, id: unit._id } })
    }
}

export default
withStyles(styles)(
    withTranslation()(
        connect(mapStateToProps, { genericStateChange })(
            UnitSelectorComponent)));
