import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { getPreRegisterData, registerUser } from '../actions/data-manager';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { PUBLIC_KEY, PRIVATE_KEY } from './TenantInfo/TenantInfo';
import { Checkbox, FormControlLabel, Radio } from '@material-ui/core';
import { KeyDialog } from './TenantInfo/KeyDialog';
import { keyToBase64 } from './TenantInfo/TenantInfo';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, nameList, theme) {
    return {
        fontWeight:
            nameList.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function Registration(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [selectedUnits, setSelectedUnits] = React.useState([]);
    const [unitsAddress, setUnitsAddress] = React.useState([]);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [registered, setRegistered] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");
    const [errorField, setErrorField] = React.useState("")
    const [publicKey, setPublicKey] = React.useState("")
    const [privateKey, setPrivateKey] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    function wrap_getPreRegisterData() {
        console.log(props.match.params.token)
        getPreRegisterData(props.match.params.token, (err, data, dsp) => {
            if (err) {
                return
            }
            setUnitsAddress(data.units)
        })
    }

    const handleOk = (pub, priv) => {
        setPublicKey(pub);
        setPrivateKey(priv);
        setOpenDialog(false);
    }

    React.useEffect(wrap_getPreRegisterData, [getPreRegisterData]);

    const handleChange = event => {
        setSelectedUnits(event.target.value);
    };

    return (registered ?
        <Redirect to="/login" />
        :
        <div className={classes.root}>
            <Container component="main" maxWidth="xs">
                <Typography component="h1" variant="h5">
                    {t("Registration")}
                </Typography>
                {/* <form className={classes.form} noValidate> */}
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={t("Firstname")}
                    name="firstName"
                    autoComplete="email"
                    autoFocus
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    error={"firstName" === errorField}
                    helperText={"firstName" === errorField ? errorText : null}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label={t("Lastname")}
                    name="lastName"
                    autoComplete="email"
                    autoFocus
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    error={"lastName" === errorField}
                    helperText={"lastName" === errorField ? errorText : null}
                />

                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-chip">{t("My units")}</InputLabel>
                    <Select
                        multiple
                        value={selectedUnits}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip key={value.address} label={value.address} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                        error={"units" === errorField}
                    >
                        {unitsAddress.map(name => (
                            <MenuItem key={name.address} value={name} style={getStyles(name.id, selectedUnits, theme)}>
                                {`${name.address}(${t(name.type)})`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("Email")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    error={"email" === errorField}
                    helperText={"email" === errorField ? errorText : null}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="mobile"
                    label={t("Mobile")}
                    name="mobile"
                    autoFocus
                    value={mobile}
                    onChange={e => setMobile(e.target.value)}
                    error={"mobile" === errorField}
                    helperText={"mobile" === errorField ? errorText : null}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={t("Username")}
                    name="username"
                    autoComplete="email"
                    autoFocus
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    error={"username" === errorField}
                    helperText={"username" === errorField ? errorText : null}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label={t("Password")}
                    name="password"
                    type="password"
                    autoComplete="email"
                    autoFocus
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox value={openDialog} checked={checked} />}
                    label="Generisi kljuceve za glasanje"
                    onClick={(e) => {
                        setOpenDialog(e.target.checked);
                        setChecked(e.target.checked)
                    }} />
                {openDialog && <KeyDialog keyToBase64={keyToBase64}
                    open={openDialog}
                    handleCancel={e => { setOpenDialog(false); setChecked(false) }}
                    handleOk={handleOk} />}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={
                        (e) => {
                            console.log({ firstName, lastName })
                            registerUser(props.match.params.token,
                                {
                                    firstName, lastName, email, username, password, mobile,
                                    pendingBuildings: {
                                        id: props.match.params.token,
                                        units: selectedUnits
                                    },
                                    publicKey
                                },
                                (err, data, dsp) => {
                                    if (err) {
                                        if (err.wrongField) {
                                            setErrorField(err.wrongField)
                                            setErrorText(err.errorMessage)
                                        }
                                        return
                                    }

                                    if(publicKey){
                                        localStorage.setItem(PUBLIC_KEY, publicKey)
                                    }

                                    if (privateKey) {
                                        localStorage.setItem(PRIVATE_KEY, privateKey)
                                    }
                                    setRegistered(true)
                                })
                        }}
                >{t("REGISTER")}</Button>
            </Container>
        </div>
    );
}
