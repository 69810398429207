import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import { Grid } from '@material/react-layout-grid';

import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export class ObligationList extends Component {

    render() {
        let { t } = this.props
        let building = this.props.building || {}

        let finance = building.finance || {}

        let buildingPayable = building.finance && building.finance.payable && building.finance.payable.reduce((acc, x) => acc + x.price, 0)
        let inflowSum = building.finance && building.finance.inflow && building.finance.inflow.reduce((acc, x) => acc + x.amount, 0)
        let outflowSum = building.finance && building.finance.outflow && building.finance.outflow.reduce((acc, x) => acc + x.amount, 0)
        let sumFundsPayables = (building.finance && building.finance.fund && building.finance.fund.reduce((acc, x) => acc + (x.receivable || 0), 0 ) || 0)


        let fullBalance = building.finance && inflowSum && buildingPayable && (inflowSum - buildingPayable - sumFundsPayables)
        let cashBalance = building.finance && building.finance.balance
        let calculatedCashBalance = building.finance && (inflowSum - outflowSum)

        let outflows = building.finance ? building.finance.outflow : []

        let fundMap = (finance.fund||[]).reduce((a,x)=> ({...a, [x._id]:x.name}),{})
        let fundOutflows = (outflows || []).filter(x=>!!x.fundId)
        fundOutflows = fundOutflows.map(x=> ({name: x.reason, price: x.amount, accounting: fundMap[x.fundId], created: x.date, type: "fund"}))

        let payables = building.finance ? building.finance.payable : []

        payables.push(...fundOutflows)

        payables.sort((a,b)=> Date.parse(a.created) - Date.parse(b.created))

        return (
            <Container>
                <Grid>
                    {!!fullBalance && <Typography variant="body2" >
                        {t("Balance") + ": " + t("priceFormat", { num: fullBalance })}
                        {" ["  + t("priceFormat", { num: inflowSum })+ " (" + t("Income") + ") "}
                        {" - " + t("priceFormat", { num: buildingPayable + sumFundsPayables }) + " (" + t("Payables") + ")]"}
                        {this.props.action && <>
                            <br />
                            {t("Cash balance") + ": " + t("priceFormat", { num: cashBalance })}
                            <br />
                            {t("Calculated cash balance") + ": " + t("priceFormat", { num: calculatedCashBalance })}
                        </>}
                    </Typography>
                    }
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell >{t("Payables")}</TableCell>
                            <TableCell >{t("Accounting")}</TableCell>
                            <TableCell >{t("Price")}</TableCell>
                            {this.props.action && <TableCell >{t("Actions")}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payables.map(payable => (
                            <TableRow key={payable._id}>
                                {}
                                <TableCell >{payable.name} </TableCell>
                                <TableCell >{t(payable.accounting)} </TableCell>
                                <TableCell >{t("priceFormat", { num: (payable.unitPrice || payable.price) })
                                } <> {(payable.status === "paid" || payable.type === "fund") &&
                                    <Tooltip title={t("Service paid")} >
                                        <CheckCircleIcon fontSize="small" color="primary" />
                                    </Tooltip>
                                } </>
                                </TableCell>
                                {this.props.action && !payable.type && <TableCell >{this.props.action(payable)}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Container >
        )
    }
}