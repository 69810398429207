import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

import { Grid } from '@material/react-layout-grid';
// import Button from '@material-ui/core/Button';
// import TextField, { Input } from '@material/react-text-field';
// import Paper from '@material-ui/core/Paper'

import { FormLabel, Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { getTenentVoting } from "../actions/data-manager";
import SignerDialog from "./SignerDialog";

const PRIVATE_KEY = "privateKey"
const IN_PROGRESS = "in progress"
const VOTED = "voted"
// const TENANT_NO_VOTING_KEY = "no key"
const NO_VOTING = "no voting"



const NOT_ALLOWED = "not allowed";
class VotingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            openSignerDialog: false,
        }
    }

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        this.props.dispatch(getTenentVoting(this.props.buildingId, (err, res, disp) => {
        }))
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }

    render() {
        let { t } = this.props
        let voting = this.props.tenantVoting || {}
        const privateKey = localStorage.getItem(PRIVATE_KEY)
        let status = voting.status || NOT_ALLOWED
        let hasVotingKey = voting.hasVotingKey

        return (<>
            {this.state.openSignerDialog && <SignerDialog
                loadedKey={privateKey}
                handleCancel={() => { this.setState({ openSignerDialog: false }) }}
                handleSign={() => { this.setState({ openSignerDialog: false }) }}
                dispatch={this.props.dispatch}
                buildingId={this.props.buildingId}
                votingId={voting._id}
                candidateId={voting.candidates ? voting.candidates[this.state.value]._id : undefined}
            />}
            <Container>
                <br />
                {
                    // (!privateKey && <FormLabel>{t("Your account doesn't have a key GOTO INFO.")}</FormLabel>) ||
                    (status === VOTED && <FormLabel>{t("Thank you for voting.")}</FormLabel>) ||
                    (!hasVotingKey && <FormLabel>{t("Please set up your private and public keys in")}<a href="./info"> {t("informations")}</a></FormLabel>) ||
                    ((status === NO_VOTING) && <FormLabel>{t("There are no active voting.")}</FormLabel>) ||

                    //TODO ADD TRANSLATE  !!!! 

                    (status === NOT_ALLOWED && <FormLabel>{t("Not registered for voting.")}</FormLabel>) ||
                    (status === IN_PROGRESS && <>
                        <Grid>
                            <FormLabel>{t("Please vote regarding subject ") + voting.subject} </FormLabel>
                            <RadioGroup value={this.state.value} onChange={this.handleChange} >
                                {voting.candidates.map((candidate, ix) => {

                                    return <FormControlLabel key={ix} label={candidate.name} control={<Radio value={ix.toString()} />} />
                                })}
                            </RadioGroup>
                        </Grid>
                        <Grid>
                            <Button variant="contained" color="primary" disabled={!Boolean(this.state.value)} onClick={
                                (e) => {
                                    e.preventDefault();
                                    this.setState({ openSignerDialog: true })
                                }}>
                                {t("Vote")}
                            </Button>

                        </Grid>
                    </>)
                }
            </Container>
        </>
        )
    }
}

function mapStateToProps(state) {
    return {
        bld: state.buildings,
        buildingId: state.tenantBuildingId,
        building: state.tenantBuilding,
        tenantUnits: state.tenantUnits,
        tenantVoting: state.tenantVoting
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, null)(
            VotingComponent));
