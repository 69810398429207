import React, { useState } from 'react'
import { connect } from "react-redux";
import { TextField, Button, Container, Table, TableHead, TableRow, TableCell, TableBody, Paper, Divider, FormControl, FormControlLabel, FormLabel, Tooltip, IconButton, Radio } from '@material-ui/core';
import BuildingSelector from '../BuildingSelector';
import { fetchBuildings, postNewVoting, postManualVoting, thunkRequestManager } from '../../actions/data-manager.js';
// import { createDocuments } from '../../actions/data-manager'
import { createDocuments } from '../../actions'
import { fetchTemplateMeetingVoting, fetchTemplateLatestVoting } from '../../actions/data-manager'
// import { Grid } from '@material/react-layout-grid';
import { useTranslation } from 'react-i18next';
import UnitSelector from '../UnitSelector';
import { makeStyles } from '@material-ui/styles';
import Templates from '../Templates';
import { RadioGroup } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { SportsRugbySharp } from '@material-ui/icons';
// import { KeyboardDatePicker } from '@material-ui/pickers';

const INIT_STATUS = "init"
export const WORKING_VOTING = "workingVoting"

const GARAGE_TYPE = "garage"

const SELECTION_NON_GARAGE = "non garage"

const LOCAL_NOVOTING = "no voting"
const LOCAL_PREPARING = "preparing"
const LOCAL_IN_PROGRESS = INIT_STATUS


// function getVotingSelection(units, selection) {
//     if (selection !== SELECTION_NON_GARAGE) {
//         throw Error("Not implemented")
//     }

//     let selectedUnits = units.filter(x => x.type !== GARAGE_TYPE)
//     let res = selectedUnits.map(x => x._id.toString())
//     return res
// }

const useStyle = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        paddingInline: "5vw",
        paddingBlock: "25px",
        '& .MuiDivider-root': {
            marginBlockEnd: "30px",
        },
    },
    stats: {
        display: "flex",
        flexDirection: "row",
        marginBlock: "25px",
        gap: "30px",
        justifyContent: "center",
        '& .MuiPaper-root': {
            minHeight: "60px",
            minWidth: "220px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            borderRadius: "30px"
        },
        "@media (max-width: 1050px)": {
            flexDirection: 'column',
        }
    },
    statsText: {
        fontSize: "25px",
        fontWeight: "bold"
    },
    status: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        marginBlockEnd: "10px",
        '& span': {
            fontWeight: "bolder"
        },
        '& .MuiIconButton-root': {
            padding: 0,
            // "@media (max-height:720px)": {transform: 'scale(0.7)'},
            '&:hover': {
                backgroundColor: "rgba(0,0,0,0)"
            }
        },
        // "@media (max-height:720px)": {fontSize: '10px'}
    },
    statusInfo: {
        marginLeft: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        gap: "5px"

    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        '& span': {
            fontWeight: "bolder"
        },
        '& .MuiIconButton-root': {
            paddingBlockStart: "0px",
            marginInlineStart: "10px",
            '&:hover': {
                backgroundColor: "rgba(0,0,0,0)"
            }
        }

    },
    table: {
        // width: "90%",
        alignSelf: "center"
    },
    tableWrapper: {
        display: "flex",
        justifyContent: "center"
    },
    manualVoting: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "20px"
    },

    finalizeVoting: {
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    },
    finalizeButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"

    },
    repeatVoting: {
        display: "flex",
        flexDirection: "column",
        gap: "5px"
    },
    finalizeButton: {
        marginLeft: "auto",
        alignSelf: "flex-end"
    },
    subject: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
    },
    printing: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px'
    },
    printButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '5px',
        "@media (max-width: 720px)": {
            flexDirection: "column",
            alignItems: 'center',
            '& .MuiButton-containedPrimary': {
                width: '100%'
            }
        }
    }
});

function loadWorkingVoting(buildingId){
    return localStorage.getItem(WORKING_VOTING+buildingId);
}

function removeWorkingVoting(buildingId) {
    window.localStorage.removeItem(WORKING_VOTING+buildingId);
}

function ManagerVoting(props) {

    const { t } = useTranslation();
    let { dispatch } = props
    React.useEffect(() => {
        dispatch(fetchBuildings())
    }, [dispatch]);

    const classes = useStyle();


    let building = props.bld[props.selectedBuildingIndex] || {}

    let voting = building.voting || {}
    let displayVoting = voting.status === INIT_STATUS ? voting : JSON.parse(loadWorkingVoting(building._id) || "{}")
    if (displayVoting.start) {
        displayVoting.start = new Date(displayVoting.start)
    }
    if (displayVoting.end) {
        displayVoting.end = new Date(displayVoting.end)
    }
    if (parseInt(props.selectedBuildingIndex) < 0) {
        displayVoting = {}
    }

    const [votingDate, setVotingDate] = React.useState(displayVoting.start
        ||
        new Date()
    )


    let state;
    if (voting.status === INIT_STATUS) {
        state = LOCAL_IN_PROGRESS
    } else if (loadWorkingVoting(building._id)) {
        state = LOCAL_PREPARING
    } else {
        state = LOCAL_NOVOTING
    }

    let { voted, totalSelection, quorum, unavailable/*, unavailableProjection*/ } = voting.currentResults || {}

    function formatState(state) {
        if (state === "init") {
            return "IN PROGRESS"
        } else if (state === "preparing") {
            return "PREPARING"
        } else if (state === "no voting") {
            return "NOT IN PROGRESS"
        } else
            return state
    }

    let activePercentage = ` ${(100 * voted / (totalSelection - unavailable)).toFixed(2)}%`;

    const [radioValue, setRadioValue] = useState("");

    return <>
        <Container>
            <BuildingSelector />
            <br />
            {state === LOCAL_NOVOTING && <>
                <span>{`Status: ${t(formatState(state))}`}</span>
                <span>  , {t("start voting from")}  <a href="./issues">{t("issues")}</a> </span>
            </>}
            {state === LOCAL_PREPARING &&
                <Paper className={classes.root}>
                    <span style={{ marginLeft: "auto", color: "orange" }}>{`Status: ${t(formatState(state))}`}</span>
                    <div>
                        <TextField label={t("Subject")} value={displayVoting.subject || ""} />
                    </div>
                    <div>
                        <TextField label={t("Description")} multiline fullWidth value={displayVoting.description || ""} />
                    </div>
                    <div>
                        <TextField label={t("End date")} type="date"
                            value={formatDateOnly(votingDate)}
                            onChange={e => { setVotingDate(new Date(e.target.value)) }}
                        />
                    </div>
                    {/* <div>
                    <TextField label={"End"} type="date" value={displayVoting.end} InputProps={{ readOnly: true }} />
                </div> */}
                    {/* <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("Option")}</TableCell><TableCell>{t("Votes")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(displayVoting.candidates || []).map(x =>
                            <TableRow key={x.name}>
                                <TableCell>{x.name}</TableCell><TableCell>{(x.votes || []).length}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table> */}
                    <div>
                        <Button disabled={displayVoting.status === INIT_STATUS || !props.selectedBuilding} variant="contained" color="primary" onClick={e => {

                            if (window.confirm(t(`?Start voting`))) {
                                dispatch(postNewVoting(props.selectedBuilding,
                                    {
                                        subject: displayVoting.subject,
                                        description: displayVoting.description,
                                        candidates: displayVoting.candidates,
                                        start: votingDate,
                                        end: votingDate
                                        // start: displayVoting.start,
                                        // end: displayVoting.end
                                    },
                                    (err, res) => {
                                        if (!err) {
                                            removeWorkingVoting(building._id);
                                        }
                                    }
                                ))
                            }
                        }
                        } >{t("Start Voting")}</Button></div>
                    <div>
                        <Button disabled={displayVoting.status === INIT_STATUS || !props.selectedBuilding}
                            variant="contained" color="primary"
                            onClick={e => {
                                removeWorkingVoting(building._id);
                                setVotingDate(undefined)
                            }} >
                            {t("End Voting Preparation")}
                        </Button>
                    </div>
                </Paper>
            }
            {state === LOCAL_IN_PROGRESS && <>

                <Paper className={classes.root}>
                    <div className={classes.status}>
                        <div className={classes.subject}>
                            <span >{t("Subject")}: </span>
                            <span>{voting.subject}</span>
                        </div>
                        <div className={classes.statusInfo}>
                            <span style={{ color: "green" }}>{`STATUS: ${t(formatState(state))}`}</span>
                            <span>{t("UNTIL")}: {formatDateOnly(new Date(voting.end))}</span>
                            <span> {`${voting.repeating ? `${(voting.repeating + 1)}.  ${t("ITERATION")}` : `1. ${t("ITERATION")}`}`} </span>
                        </div>
                    </div>

                    <Divider />

                    {voting.selection && <>

                        <div className={classes.header}>
                            <span>{t("CURRENT RESULTS")}:</span>
                        </div>

                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow >
                                        <TableCell>{t("Option")}</TableCell><TableCell align="right">{t("Votes")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(displayVoting.candidates || []).map(x =>
                                        <TableRow key={x.name}>
                                            <TableCell>{x.name}</TableCell><TableCell align="right">{(x.votes || []).length}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </>}
                    <div className={classes.stats}>
                        <Paper>
                            <span className={classes.statsText}>{` ${totalSelection}`}</span>
                            <span>{t("Total")}</span>
                        </Paper>
                        <Paper>
                            <span className={classes.statsText}>{totalSelection - unavailable}</span>
                            <span>{t("Available")}</span>
                        </Paper>
                        <Paper>
                            <span className={classes.statsText}>{`${voted}`}</span>
                            <span>{t("Voted")}</span>
                        </Paper>
                        {<Paper>
                            <span className={classes.statsText}>{activePercentage}</span>
                            <span>{t("Active percentage")}</span>
                        </Paper>}
                    </div>

                    <Divider />
                    <div className={classes.manualVoting}>
                        <div className={classes.header}>
                            <span>{t("MANUAL VOTING")}</span>
                        </div>
                        <div style={{ alignSelf: "flex-start" }}>
                            <UnitSelector />
                        </div>
                        {building.units[props.selectedUnitIndex] !== undefined && <>
                            <div style={{ alignSelf: "flex-start" }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Izaberite opciju:</FormLabel>
                                    <RadioGroup value={radioValue} onChange={(event) => setRadioValue(event.target.value)}>
                                        {(displayVoting.candidates || []).map(x =>
                                            <FormControlLabel label={x.name} value={x._id} key={x._id} control={<Radio />} />
                                        )}
                                    </RadioGroup>


                                </FormControl>
                            </div>
                            

                            <div style={{ display: "flex" }}>
                                <Button style={{ marginLeft: "auto" }} variant="contained" color="primary" onClick={e => {
                                    const unitId = building.units[props.selectedUnitIndex]._id;
                                    // if (window.confirm(t('Vote for unit?'))) {
                                    dispatch(postManualVoting(props.selectedBuilding, unitId, radioValue,
                                        (err, res) => {
                                            if (err) {
                                                alert(err.errorMessage)
                                            }
                                        }
                                    ));
                                    setRadioValue("");
                                    // }
                                }}>Potvrdite</Button>
                            </div>
                        </>
                        }
                    </div>
                    <Divider />
                    <div className={classes.printing}>
                        <div className={classes.header}>
                                <span>{t("Documents for printing")}</span>
                        </div>
                        <div className={classes.printButtons}>
                            <Button onClick={
                                (e) => {
                                    e.preventDefault();
                                    dispatch(createDocuments({ buildingId: props.selectedBuilding }));
                                }} variant="contained" color="primary">{t("Single voting")} 
                            </Button>

                            <Button onClick={
                                (e) => {
                                    e.preventDefault();
                                    fetchTemplateMeetingVoting(props.selectedBuilding);
                                }} variant="contained" color="primary" >{t("Group voting")}
                            </Button>

                            <Button variant="contained" color="primary"
                            disabled={!props.bld[props.selectedBuildingIndex] || !props.bld[props.selectedBuildingIndex].archiveVoting || !props.bld[props.selectedBuildingIndex].archiveVoting.length}
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    fetchTemplateLatestVoting(props.selectedBuilding);
                                }}> Poslednji zapisnik </Button>
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.finalizeVoting}>
                        <div className={classes.header}>
                            <span>{t("FINALIZE VOTING")}</span>
                        </div>
                        <div className={classes.finalizeButtons}>
                            <div className={classes.repeatVoting}>
                                {/* <TextField label={"Start"} type="date" value={displayVoting.start} /> */}
                                <TextField label={"Repeated voting date"} type="date"
                                    value={formatDateOnly(votingDate)}
                                    onChange={e => { setVotingDate(new Date(e.target.value)) }}
                                />
                                {/* <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        // id="date-picker-inline"
                        label="Date"
                        value={votingDate}
                        onChange={e => { setVotingDate(e.target.value); console.log(e.target.value) }}
                    // KeyboardButtonProps={{
                    //     'aria-label': 'change date',
                    // }}
                    /> */}

                                <Button disabled={displayVoting.status !== INIT_STATUS} variant="contained" color="primary" onClick={e => {
                                    if (window.confirm(t('Repeat voting?'))) {
                                        dispatch(thunkRequestManager(`/b/${props.selectedBuilding}/voting/repeat`,
                                            { start: votingDate, end: votingDate },
                                            (err, res) => {
                                                if (err) {
                                                    alert(err.errorMessage)
                                                }
                                            }
                                        ))
                                    }
                                }} >{t("Repeat")}</Button>
                            </div>

                            <Button className={classes.finalizeButton} disabled={displayVoting.status !== INIT_STATUS} variant="contained" color="primary" onClick={e => {
                                if (window.confirm(t('Finalize voting?'))) {
                                    dispatch(thunkRequestManager(`/b/${props.selectedBuilding}/voting/finalize`, null, (err, res) => {
                                        if (err) {
                                            alert(err.errorMessage)
                                        }
                                    }
                                    ))
                                }
                            }} >{t("FINALIZE")}</Button>
                        </div>
                    </div>
                    {/* <Button disabled={displayVoting.status === INIT_STATUS} variant="contained" color="primary" >PRINT PAPER VOTING</Button> */}
                </Paper></>}
            <br />
            {/* <Tooltip title="Stampaj zapisnik">
                <Button className={classes.finalizeButton} variant="contained" color="primary"
                    disabled={!props.bld[props.selectedBuildingIndex] || !props.bld[props.selectedBuildingIndex].archiveVoting || !props.bld[props.selectedBuildingIndex].archiveVoting.length}
                    onClick={
                        (e) => {
                            e.preventDefault();
                            fetchTemplateLatestVoting(props.selectedBuilding);
                        }}>
                    Poslednji zapisnik </Button>
            </Tooltip> */}

        </Container>
    </>

    function formatDateOnly(date) {
        console.log(date)
        if (date) {
            return `${date.getFullYear()}-${(date.getUTCMonth() < 9 ? "0" : "")}${date.getUTCMonth() + 1}-${(date.getUTCDate() < 10 ? "0" : "")}${date.getUTCDate()}`;
        } else {
            return ""
        }

    }
}

function mapStateToProps(state) {
    return {
        selectedBuilding: state.selectedBuilding,
        selectedBuildingIndex: state.selectedBuildingIndex,
        selectedUnitIndex: state.selectedUnitIndex,
        bld: state.buildings || []
    }
}

export default connect(mapStateToProps, null)(ManagerVoting);
