import React, { } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function PaymentDialog(props) {

    // const [dataObject, setDataObject] = React.useState(props.dataObject)
    const [dataObject, setDataObject] = React.useState({invoiceId:props.invoiceId, amount: props.amount})

    const { t } = useTranslation();
    

    // useEffect(() => {
    //     setDataObject(props.dataObject)
    // }, [props.dataObject])

    const handleChange = name => event => {
        setDataObject({...dataObject, [name]: event.target.value } );
    };

    return (
        <Dialog open={props.open}  >
            <DialogTitle id="form-dialog-title">{t("Payment")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="invoiceId"
                    label={t("Invoice Id")}
                    // type="email"
                    fullWidth
                    value={dataObject.invoiceId}
                    onChange={handleChange('invoiceId')}
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="amount"
                    label={t("Amount")}
                    // multiline
                    type="Number"
                    fullWidth
                    value={dataObject.amount}
                    onChange={handleChange('amount')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCancel} color="primary">{t("Cancel")}</Button>
                <Button onClick={() => {dataObject.amount=parseFloat(dataObject.amount); props.handleUpdate(dataObject) }} color="primary">{t("Register")}</Button>
            </DialogActions>
        </Dialog>
    );
}