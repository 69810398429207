import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import {fetchBuildings} from './../actions/data-manager'
import { withTranslation } from "react-i18next";


class BuildingListComponent extends Component {
  constructor(props) {
    console.log("TESTT")
    console.log(props)
    super(props);
  }
  
  componentDidMount(){
    //TODO: handle connection errors
    this.props.dispatch(fetchBuildings())
  }

  render() {
    let { t } = this.props
    return (
      <Container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell >{t("Building Name")}</TableCell>
              <TableCell >{t("Unit Count")}</TableCell>
              <TableCell >{t("Unit Area")}</TableCell>
              <TableCell >{t("Balance")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.bld.map(building => (
              <TableRow key={building.name}>
                <TableCell >{building.name}</TableCell>
                <TableCell >{building.unitCount} </TableCell>
                <TableCell >{building.totalArea} </TableCell>
                <TableCell >{building.balance} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    )
  }
}

// const mapStateToProps = state => {
function mapStateToProps(state) {
  // console.log("BUILDING_LIST_MAP")
  let { buildings } = state
  buildings = buildings || []
  let buildingList = []
  buildings.forEach(building => {
    let totalArea = 0;
    if (building.units) {
      building.units.forEach(unit => {
        totalArea += unit.size
      });
    }
    buildingList.push(
      {
        name: building.name,
        totalArea: totalArea,
        unitCount: (building.units ? building.units.length : -1),
        balance: (building.finance ? building.finance.balance : -1)
      })
  });
  return {
    bld: buildingList,
  };
}

const BuildingList = connect(mapStateToProps)(BuildingListComponent);

export default withTranslation()(BuildingList);