import React from "react"
import './Register.css';
import { RegisterForm } from "./RegisterForm";

export const Register2 = () => {

    return (
        <div className="RegisterWrapper">
            <div className="Logo">
                <img src={process.env.PUBLIC_URL + '/assets/react-js.svg'} alt='logo' />
            </div>
            <div className="Form">
                <RegisterForm />
            </div>
        </div>
    )
}