import React, { useState } from 'react';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Button, Box, Typography, IconButton, Tooltip, Divider } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { postVotingKey, getTenantInfo } from '../../actions/data-manager';
// import copy from 'copy-text-to-clipboard'
import nacl from 'tweetnacl'
import { BasicInfo } from './BasicInfo';
import { KeyDialog } from './KeyDialog';
import copyTextToClipboard from 'copy-text-to-clipboard';
import { makeStyles } from '@material-ui/styles';

export const PUBLIC_KEY = 'publicKey'
export const PRIVATE_KEY = "privateKey"

const useStyles = makeStyles({
    wrapper: { 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '80vh' 
    },
    infoCard: { 
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', 
        paddingBlock: '3vh',
         paddingInline: '3vw', 
         borderRadius: '10px', 
         display: 'flex', 
         flexDirection: 'column', 
         gap: '10px' 
    },
    divider: { 
        marginBlock: '10px' 
    },
    generateKey: { 
        display: 'flex', 
        justifyContent: 'center', 
        marginTop: '30px' 
    },
    votingText: { 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
    },
    keysWrapper: { 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center' 
    },
    privateKeyInput: { 
        display: 'flex',
        flexDirection: 'column'
    }
});

function TenantInfo(props) {
    const { t } = useTranslation();
    const { dispatch } = props
    const classes = useStyles();


    const [openDialog, setOpenDialog] = useState(false)
    const [publicKey, setPublicKey] = useState(localStorage.getItem(PUBLIC_KEY))
    const [privateKey, setPrivateKey] = useState(localStorage.getItem(PRIVATE_KEY));
    const [userData, setUserData] = useState({});
    const [invalidKey, setInvalidKey] = useState(true);

    React.useEffect(() => {
        dispatch(getTenantInfo((err, user) => {
            setUserData(user);

            if (!publicKey && user.votingKey) {
                localStorage.setItem(PUBLIC_KEY, user.votingKey)
                setPublicKey(user.votingKey);
            }
        }))
    }, [dispatch]);

    const handleOk = (pub, priv) => {

        dispatch(postVotingKey(pub, (err, data) => {
            if (!err) {
                localStorage.setItem(PUBLIC_KEY, pub)
                if (priv) {
                    localStorage.setItem(PRIVATE_KEY, priv)
                    setPrivateKey(priv);
                }
                setPublicKey(pub)
                setOpenDialog(false)
            } else {
                if (err.status === 412) {
                    alert("Not allowed already set key, contact your building manager")
                }
            }
        }))

    }

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.infoCard}>
                <BasicInfo userData={userData} />
                <Divider className={classes.divider}/>
                <Box className={classes.votingText}>
                    <Typography variant="h4">{t('Voting keys')}</Typography>
                </Box>

                {openDialog &&
                    <KeyDialog
                        keyToBase64={keyToBase64}
                        open={openDialog}
                        handleCancel={e => setOpenDialog(false)}
                        handleOk={handleOk}
                    />
                }

                {!publicKey &&
                    <Box className={classes.generateKey}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setOpenDialog(true)
                            }}>
                            {t('Generate key')}
                        </Button>
                    </Box>
                }
                {publicKey &&
                    <Box >
                        <Box className={classes.keysWrapper}>
                            <Typography variant="h5">{t('Public key')}: </Typography>
                            <IconButton onClick={() => { copyTextToClipboard(privateKey) }}>
                                <Tooltip title={t("Copy public key")}>
                                    <VpnKeyIcon />
                                </Tooltip>
                            </IconButton>
                        </Box>
                        {privateKey &&
                            <Box className={classes.keysWrapper}>
                                <Typography variant="h5">{t('Private key')}:</Typography>
                                <IconButton onClick={() => { copyTextToClipboard(privateKey) }}>
                                    <Tooltip title={t("Copy private key")}>
                                        <VpnKeyIcon />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        }
                        {!privateKey &&
                            <Box sx={classes.privateKeyInput}>
                                <TextField 
                                    error={invalidKey} 
                                    helperText={invalidKey ? t("Invalid key") : ""} 
                                    label={t("Enter private key")} 
                                    fullWidth 
                                    onChange={(event) => {
                                        try {
                                            let buf = Buffer.from(event.target.value, 'base64');
                                            let keyPair = nacl.sign.keyPair.fromSecretKey(buf);

                                            if (keyToBase64(keyPair.publicKey) === publicKey) {
                                                setPrivateKey(event.target.value);
                                                localStorage.setItem(PRIVATE_KEY, event.target.value);
                                                setInvalidKey(false);
                                            }
                                        } catch (error) {
                                            setInvalidKey(true);
                                        }
                                    }}/>
                            </Box>
                        }
                    </Box>
                }
            </Box></Box>);
}

export function keyToBase64(key) {
    return Buffer.from(key).toString('base64')
}


export default connect(null, null)(TenantInfo);


// {
//     //GENERATE
//     //      auto generated
//     // const pair = nacl.sign.keyPair()

//     //      seed-based
//     // const rnd32 = nacl.randomBytes(32);
//     // const pair = nacl.sign.keyPair.fromSeed(rnd32);

//     //      Stellar - seed based keys
//     const rnd32 = StellarSdk.Keypair.fromSecret('SDIAA4POFCOXTU67UA6PLGHDA6CAP5VKR4SPYHDAEABAHYMM72UEMLAD').rawSecretKey(); //seed from Stellar
//     // const rnd32 = nacl.randomBytes(32);
//     const pair = nacl.sign.keyPair.fromSeed(rnd32);

//     //helper
//     const pub = Buffer.from(pair.publicKey).toString('base64')
//     const priv = Buffer.from(pair.secretKey).toString('base64')
//     const seed = Buffer.from(rnd32).toString('base64')

//     //DATA
//     let data = "Hello world";
//     let dataBuffer = Buffer.from(data);

//     //SIGN
//     const signatureUint = nacl.sign.detached(dataBuffer, pair.secretKey);
//     const signature = Buffer.from(signatureUint);
//     let signatureEncoded = signature.toString('base64');

//     //VERIFY
//     let isVerified = nacl.sign.detached.verify(dataBuffer, signature, pair.publicKey)


//     console.log(` Tweetnacl based signing
//     private: \t${priv}
//     public: \t${pub}
//     seed: \t${seed}
//     d: \t\t${data}
//     signature: \t${signatureEncoded}
//     verified: \t${isVerified}
//     link: \thttps://tweetnacl.js.org/#/sign
//     `);
//     clipboardy.writeSync(priv);

// }

