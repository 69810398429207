import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ObligationList } from "./ObligationList";
import {  getBuilding } from '../actions/data-manager';



function TenantBuildingFinance(props) {
    const { dispatch } = props
    React.useEffect(() => {
        dispatch(getBuilding(props.buildingId)
        )
    }, [dispatch, props.buildingId]);

    return <ObligationList {...props}>
    </ObligationList>
}


function mapStateToProps(state) {
    return {
        buildingId: state.tenantBuildingId,
        building: state.tenantBuilding
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, null)(
            TenantBuildingFinance));
