import React from 'react';
import { Box, Typography } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { useTranslation } from 'react-i18next';

export const BasicInfo = ({ userData }) => {

    const { t } = useTranslation();

    return (
        <>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h4">{t('Basic info')}</Typography>
            </Box><Box style={{ display: 'flex', alignItems: 'center' }}>
                <AccountCircleIcon />
                <Typography variant="h5">{t('Username')}: {userData.username}</Typography>
            </Box><Box style={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon />
                <Typography variant="h5">{t('Email')}: {userData.email}</Typography>
            </Box><Box style={{ display: 'flex', alignItems: 'center' }}>
                <PhoneAndroidIcon />
                <Typography variant="h5">{t('Phone')}: {userData.mobile}</Typography>
            </Box>
        </>
    );

};