import React, { useState } from 'react';
import { TextField, DialogActions, Button, Dialog, DialogTitle, DialogContent, /*makeStyles,*/ CircularProgress, DialogContentText } from '@material-ui/core';
import { postVote } from "../actions/data-manager";
import { genericAction, TENANT_VOTING_RECEIVED } from "../actions";
import {useTranslation} from 'react-i18next'
const nacl = require('tweetnacl')


// const useStyles = makeStyles({
//     paperWidthSm2: {
//         //   color: 'red',

//         //   paperWidthSm: '660px'
//         //   ,maxWidth: '700px'
//         // maxWidth: '1900px'
//         width: '630px'

//         //    paperWidthSm: { maxq: '660px'}


//     },
// });

export default function SignerDialog(props) {
    let { handleCancel, handleSign, loadedKey, dispatch, buildingId, votingId, candidateId
    } = props;

    const [privateKey, setPrivateKey] = useState(loadedKey)

    const [isLoading, setIsLoading] = React.useState(false)
    const [transactionError, setTransactionError] = useState("")
    const { t } = useTranslation();
    // const [disabled, setDisabled] = useState(false)


    let handleSignInternal = async () => {
        setTransactionError("");
        setIsLoading(true)
        try {
            //     //DATA
            let voteData = { votingId, votedFor: candidateId, time: new Date() }
            let voteDataStr = JSON.stringify(voteData)
            let dataBuffer = Buffer.from(voteDataStr)

            let buffKey = new Buffer(privateKey, 'base64')
            const signatureRaw = nacl.sign.detached(dataBuffer, buffKey);
            const signatureBuff = Buffer.from(signatureRaw);
            let signature = signatureBuff.toString('base64');

            let vote = { vote: voteDataStr, signature }

            postVote(buildingId, vote, (err, data) => {
                if (err) {
                    if (err.status === 406) {
                        setTransactionError("Not valid signature")
                    } else {
                        setTransactionError(err.errorMessage);
                    }
                    setIsLoading(false)
                } else {
                    dispatch(genericAction(TENANT_VOTING_RECEIVED, data))
                    handleSign()
                }
            })(dispatch)
        } catch (e) {
            setTransactionError(e.message);
            setIsLoading(false)
        }
    }
    return (
        <Dialog /*onClose={handleClose}*/ aria-labelledby="simple-dialog-title" open={true} fullWidth={true} maxWidth={"md"}
        // className={classes.paperWidthSm2}
        // style={
        //     {paperWidthSm:'650px', ".MuiDialog-paperWidthSm": '660px'}
        //     }
        >
            <DialogTitle id="dialog-title">{t('Sign voting')}</DialogTitle>
            <DialogContent>
                <TextField
                    multiline
                    autoFocus
                    margin="dense"
                    id="privateKey"
                    label={t("Private key")}
                    fullWidth
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                />
            </DialogContent>
            {
                transactionError &&
                <DialogContentText>
                    {transactionError}
                </DialogContentText>
            }
            <DialogActions>
                {isLoading && <CircularProgress />}
                {!isLoading && <>

                    <Button onClick={handleCancel} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleSignInternal} color="primary">
                        {t("Sign")}
                    </Button>
                </>}
            </DialogActions>
        </Dialog>
    )
}