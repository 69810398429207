import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'

// import logo from './logo.svg';
//TODO: remove from this part
import './App.css';

import Login from './components/Login'
import ManagerApp from './components/ManagerApp'
import TenantApp from './components/TenantApp'
import Registration from './components/Register'
import { Register2 } from './components/Register/Register2'

import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import { detectApp } from './store';
// import { Switch } from '@material-ui/core';

class App extends Component {
  // state = { selectedIndex: 0, open: false, english: false };

  onRouteChanged() {
    // this.setState({});
    console.log("ROUTE CHANGED ");
  }

  render() {
    //TODO: remove or use translation
    // let { t, i18n } = this.props

    const detectedApp = detectApp()
    // console.log("render APP ")
    return (
      // TODO: remove onRouteChanged
      <BrowserRouter onRouteChanged={this.onRouteChanged()} >
        <Switch>
        <Route exact path="/login" component={Login}  />
        <Route exact path="/login-manager" component={()=><Login toManager={true}/>}  />
        <Route exact path="/register/:token" component={Registration} />
        <Route exact path="/register2/:token" component={Register2} />
        <Route path="/manager" component={ManagerApp} />
        <Route path="/tenant" component={TenantApp} />
        <Route exact path="/"  >
          {
            !detectedApp && <Redirect to="/login" />
            || detectedApp === "manager" && <Redirect to="/manager" />
            || detectedApp === "tenant" && <Redirect to="/tenant" />
          }
        </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default
  withTranslation()(
    App);