import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function IssueDialog(props) {

    const [issue, setIssue] = React.useState(props.issue)

    useEffect(() => {
        setIssue(props.issue)
    }, [props.issue])

    const handleChange = name => event => {
        setIssue({ ...issue, [name]: event.target.value });
    };

    return (
        <Dialog open={props.open} PaperProps={{style: {minWidth: '60vw'}}}>
            <DialogTitle id="form-dialog-title">Issue</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Title"
                    // type="email"
                    fullWidth
                    value={issue.title}
                    onChange={handleChange('title')}
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    multiline
                    rows={5}
                    // type="email"
                    fullWidth
                    value={issue.description}
                    onChange={handleChange('description')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCancel} color="primary">Cancel</Button>
                {props.type==="update" && <Button onClick={() => { props.handleUpdate(issue) }} color="primary">Update</Button>}
                {props.type==="delete" && <Button onClick={() => { props.handleDelete() }} color="primary">Delete</Button>}
            </DialogActions>
        </Dialog>
    );
}