import { Paper, TableCell, TableRow, TableHead, Container, TableBody, Button } from "@material-ui/core";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { getAllUsers } from '../actions/data-manager';

function Users(props) {

    let { dispatch } = props;

    const [users, setUsers] = useState([]);

    useEffect(() => {
        dispatch(getAllUsers((err, users) => {
            setUsers(users);
        }))
    }, [dispatch]);


    return (
        <Container>
            <Paper>
                <TableHead>
                    <TableRow>
                        <TableCell>Username</TableCell>
                        <TableCell>Voting key</TableCell>
                        <TableCell>Confirm key</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users && users.map((user) => (
                        <TableRow key={user._id}>
                            <TableCell>
                                {user.username}
                            </TableCell>
                            <TableCell>{user.votingKey}</TableCell>
                            <TableCell>
                                <Button variant="contained">potvrdi</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Paper>
        </Container>
    );
}

export default connect(null, null)(Users);