import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addBuilding, changeNewBuilding, changeNewUnitCount } from '../actions';
// import { bindActionCreators } from 'redux';
import { withTranslation } from "react-i18next";
import Unit from './Unit'

import Button from '@material-ui/core/Button';


import TextField, { Input } from '@material/react-text-field';
import '@material/react-text-field/dist/text-field.css';

import Container from '@material-ui/core/Container';


import Card, {
    // CardPrimaryContent,
    // CardMedia,
    // CardActions,
    // CardActionButtons,
    // CardActionIcons
} from "@material/react-card";
import '@material/react-card/dist/card.css';
import { Redirect } from "react-router-dom";


//import Grid from '@material-ui/core/Grid';

import { Cell, Grid, Row } from '@material/react-layout-grid';
import '@material/react-layout-grid/dist/layout-grid.css';
import { withStyles } from '@material-ui/styles';
// import '@material/react-resizable/css/styles.css'


const styles = theme => ({})


class BuildingEditor extends Component {
    constructor(props) {
        super(props);
        this.state={redirecting: false}
    }

    handleGenericChange = (event) => { this.props.changeNewBuilding({ key: event.target.name, value: event.target.value }); }
    handleCountChange = (event) => { this.props.changeNewUnitCount(parseInt(event.target.value)); }

    render() {
        let { t } = this.props
        let { buildingName, startBalance, buildingId, buildingBankId, units } = this.props.newBuilding || {}
        let unitCount = units ? units.length : 0
        const { classes, theme } = this.props

        if (this.state.redirecting) {
            return <Redirect to="/manager/buildings" />
        }


        return (
            <Container>
                {/* <form> */}

                {/* <Card> */}

                <Grid container="true" >
                    {/* <Row>
                        <Cell> */}
                    <Grid>
                        <Card>
                            <Row>
                                <Cell>
                                    <Grid >
                                        <TextField label={t("Address")}>
                                            <Input
                                                name="buildingName"
                                                type="text"
                                                value={buildingName}
                                                onChange={this.handleGenericChange}
                                            />
                                        </TextField>
                                    </Grid>
                                </Cell>
                            </Row>
                            {/* </Cell>
                            </Row> */}

                            <Row>
                                <Cell>
                                    <Grid  >
                                        <TextField label={t("Building ID")}>
                                            <Input
                                                name="buildingId"
                                                type="text"
                                                value={buildingId}
                                                onChange={this.handleGenericChange}
                                            />
                                        </TextField>
                                    </Grid>
                                </Cell>
                                <Cell>
                                    <Grid item="true">
                                        <TextField label={t("Bank Account")}>
                                            <Input
                                                name="buildingBankId"
                                                type="text"
                                                value={buildingBankId}
                                                onChange={this.handleGenericChange}
                                            />
                                        </TextField>
                                    </Grid>
                                </Cell>
                                <Cell>
                                    <Grid>
                                        <TextField label={t("Start Balance")}>
                                            <Input
                                                name="startBalance"
                                                type="number"
                                                value={startBalance}
                                                onChange={this.handleGenericChange}
                                            />
                                        </TextField>
                                    </Grid>
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>
                                    <Grid>
                                        <TextField label={t("Unit count")}>
                                            <Input
                                                name="unitCount"
                                                disabled = {buildingName == undefined}
                                                type="number"
                                                value={unitCount}
                                                onChange={this.handleCountChange}
                                            />
                                        </TextField>
                                    </Grid>
                                </Cell>
                            </Row>
                            <Row></Row>
                        </Card>
                    </Grid>
                    <Row>
                        {
                            [...Array(unitCount)].map((e, i) =>
                                <Cell key={"unit_cell" + i}>
                                    {/* {console.log("Rendering Cell " + i)} */}
                                    <Unit id={i} ></Unit> {/* address={units[i].address} */}
                                </Cell>
                            )
                        }
                    </Row>

                    <Grid>
                        <Button variant="contained"  color="primary" onClick={
                            (e) => {
                                e.preventDefault();
                                if (window.confirm(t("Do you want to add a new building"))) {
                                    this.props.addBuilding({ name: buildingName, finance: { balance: startBalance, bankAccount: buildingBankId }, units: units })
                                    this.setState({ redirecting: true })

                                }
                            }}>
                            {t("Add Building")}
                        </Button>
                    </Grid>

                </Grid>
                {/* </form> */}
                {/* </Card> */}
            </Container>
        )
    }
}

//this.state = { buildingName: "Pozeska 4", unitCount: 0, units: [], startBalance: 0 }

const mapStateToProps = (state) => {
    return { newBuilding: state.newBuilding }
};

export default withStyles(styles)(
    withTranslation()(
        connect(mapStateToProps, { addBuilding, changeNewBuilding, changeNewUnitCount })(BuildingEditor)));