import React from 'react'
import { connect } from "react-redux";
import CommonTable from './CommonTable.jsx'
import { Container, IconButton } from '@material-ui/core';
import BuildingSelector from './BuildingSelector'
import { fetchBuildings, confirmUserUnit, assignUserUnit, setUnitOwner } from '../actions/data-manager.js';
import { Button, Select, MenuItem } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy'

import { withTranslation } from "react-i18next";

import UserDialog from './UserDialog.jsx'

import copy from 'copy-text-to-clipboard'


function Units(props) {
    let { dispatch } = props
    const { t } = props
    React.useEffect(() => {
        dispatch(fetchBuildings())
    }, [dispatch]);
    let building = (props.bld||[])[props.selectedBuildingIndex] || {}
    let units = building.units || []

    const [dialogOpened, setDialog] = React.useState(false)
    const [currentUnit, setUnit] = React.useState(null)
    const [currentBuilding, setBuilding] = React.useState(null)
    const [currentUser, setCurrentUser] = React.useState(null)

    const [pendingUnitUser, setPendingUnitUser] = React.useState({})
    const [users, setUsers] = React.useState({})

    React.useEffect(
        () => {
            setPendingUnitUser(
                units.reduce(
                    (a, u) => {
                        return {
                            ...a,
                            [u._id]: (u.pendingOwners[0] || {})._id
                        }
                    }
                    , {}
                )
            )

            let users;
            if (building.units) {
                //TODO: USE FIRST/LAST NAME FROM USERS, make server request
                //NOTE: Users are calculated from building current owners and pending owners
                //TODO: this can be calculated on demand, only when the assign is clicked, or dropdown(select)
                //Extract userMap(as users) from building.unit.owner (user is id) as unique structure
                users = building.units.reduce((acc, item) => item.owner.user in acc ? acc : (
                    (!!item.owner.user &&  (acc[item.owner.user] = item.owner)), acc), {}
                    )
                //Extract users from building.unit.pendingOwner
                building.units.forEach(unit => unit.pendingOwners.forEach(pendingOwner => { if (!(pendingOwner._id in users)) { users[pendingOwner._id] = { ...pendingOwner, user: pendingOwner._id } } }))
                delete users[undefined]
            }
            setUsers(users)

        }
        , [building.units]);

    function CustomCells(unit) {
        //Show button only if there is exactly one pending user
        return [
            <Button variant="contained" color="primary" disabled={!pendingUnitUser[unit._id] || !!unit.owner.user} onClick={
                (e) => {
                    dispatch(confirmUserUnit(building._id, unit._id, pendingUnitUser[unit._id]))
                }} >{t("Confirm")}</Button>
            ,
            <Button variant="contained" color="primary" onClick={
                (e) => {
                    setBuilding(building._id)
                    setUnit(unit)
                    setDialog(true)
                    setCurrentUser(unit.owner.user)
                }}
            >{t("Assign")}</Button>
        ]
    }

    function assignId(userID, firstName, lastName) {
        setDialog(false)
        dispatch(assignUserUnit(currentBuilding, currentUnit._id, userID, { firstName, lastName }))
    }

    function setOwner(firstName, lastName) {
        setDialog(false)
        dispatch(setUnitOwner(currentBuilding, currentUnit._id, { firstName, lastName }))
    }

    let link = "./../register/" + building._id
    return <> <Container>
        <BuildingSelector />
        {dialogOpened && <UserDialog
            open={dialogOpened}
            users={users}
            user={currentUser}
            firstName={(currentUnit.owner || {}).firstName}
            lastName={(currentUnit.owner || {}).lastName}
            handleCancel={() => setDialog(false)}
            handleUpdate={assignId}
            setOwner={setOwner}
        />}
    </Container>
        <Container>
            {t("Registration invitation")}: <a href={link}>link</a>
            <IconButton
                onClick={() => {
                    let absoluteLink = (new URL(link, document.location)).href
                    copy(absoluteLink)
                }}
            >
                <FileCopyIcon color={"primary"} />
            </IconButton>

        </Container>
        <Container>
            <CommonTable
                data={units}
                header={[t("Address"), t("Type"), t("Size"), t("Pending Owner"), t("Owner"), t("Key"), t("Confirm"), t("Assign")]}
                fields={["address", unit => t(unit.type) , "size",
                    (unit) => <>
                        <Select
                            value={pendingUnitUser[unit._id] || ""}
                            onChange={e => setPendingUnitUser({ ...pendingUnitUser, [unit._id]: e.target.value })}
                            error={(unit.pendingOwners||[]).length > 1}
                        //     name="user"
                        //     labelid="user-select"

                        >
                            {unit.pendingOwners.map(pendingOwner => {
                                return <MenuItem value={pendingOwner._id} key={pendingOwner._id} >{
                                    `${pendingOwner.firstName} ${pendingOwner.lastName}`}
                                </MenuItem>
                            })
                            }
                        </Select>

                    </>
                    ,
                    (unit) => {
                        return ((unit.owner.user ? "(R) " : "") + unit.owner.firstName + " " + unit.owner.lastName).trim()
                    }
                    , unit => !!unit.votingKey ? unit.votingKey.substr(0,3) + ".." + unit.votingKey.slice(-3) :"" 
                ]}
                createCustomCells={CustomCells}
                keyProperty={"_id"}
            />
        </Container>
    </>
}


function mapStateToProps(state) {
    return {
        selectedBuilding: state.selectedBuilding,
        selectedBuildingIndex: state.selectedBuildingIndex,
        bld: state.buildings
    }
}

export default withTranslation()(connect(mapStateToProps, null)(Units));

