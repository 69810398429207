import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { Grid } from '@material/react-layout-grid';
import Button from '@material-ui/core/Button';
// import TextField, { Input } from '@material/react-text-field';
import Checkbox from '@material-ui/core/Checkbox';

import BuildingSelector from './BuildingSelector'
import { postNewDocument, fetchBuildings, deleteDocument } from './../actions/data-manager'

import { Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, IconButton, TextField } from "@material-ui/core";
import { withTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete';

class ManagerDocsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            link: "",
            isDropbox: true
        }
    }

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        this.props.dispatch(fetchBuildings())
        // this.props.dispatch(getTenantDocs(this.props.buildingId))
    }

    handleGenericChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleDropBoxChange = (event) => {
        this.setState({ isDropbox: event.target.value !== "true" })
    }

    render() {
        let { t } = this.props
        let building = this.props.bld[this.props.selectedBuildingIndex] || {}
        if (this.props.isClientComponent) {
            building = this.props.building || {}
        }
        let docsTmp = building.docs || []
        let allDocs = docsTmp.all || []
        // console.log(invoices)

        let { name } = this.state
        let { link } = this.state
        let { isDropbox } = this.state
        let isEditable = !this.props.isClientComponent
        return (
            <Container>
                {isEditable &&
                    <Grid>
                        <BuildingSelector />
                    </Grid>
                }
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell >{t("Name")}</TableCell>
                            <TableCell >{t("Link")}</TableCell>
                            <TableCell >{t("Actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allDocs.map(doc => (
                            <TableRow key={doc._id}>
                                <TableCell >{doc.name}</TableCell>
                                <TableCell ><a href={doc.link}>link</a> </TableCell>
                                <TableCell>
                                    <IconButton size="small"
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.props.dispatch(deleteDocument(this.props.selectedBuilding, doc))
                                            // this.setState({ isNewPrepared: false, selected: undefined, title: "", description: "" })
                                            // this.setState({ selected: undefined, title: "", description: "", isNewPrepared: true })
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" color={"primary"} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {isEditable &&
                    <Grid>
                        <TextField label={t("Name")}
                            name="name"
                            // type="text"
                            value={name}
                            onChange={this.handleGenericChange}

                        >
                        </TextField>
                        {/* </Grid>
                <Grid> */}
                        <TextField label={t("Link")}
                            name="link"
                            // type="text"
                            value={link}
                            onChange={this.handleGenericChange}
                        >
                        </TextField>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isDropbox"
                                    checked={isDropbox}
                                    value={isDropbox}
                                    onChange={this.handleDropBoxChange}
                                />
                            }
                            label="Dropbox link"
                        />

                        <Button variant="contained" color="primary" onClick={
                            (e) => {
                                e.preventDefault();
                                let link = this.state.link
                                if (this.state.isDropbox) {
                                    link = link.replace("www.dropbox", "dl.dropboxusercontent")
                                }
                                this.props.dispatch(postNewDocument(this.props.selectedBuilding,
                                    this.state.name,
                                    link,
                                    (err, res) => {
                                        this.setState({ name: "", link: "" })
                                    }
                                ))
                            }}>
                            {t("Add Document")}
                        </Button>
                    </Grid>
                }
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedBuilding: state.selectedBuilding,
        selectedBuildingIndex: state.selectedBuildingIndex,
        bld: state.buildings,
        buildingId: state.tenantBuildingId,
        building: state.tenantBuilding
    }
}


export default
    withTranslation()(
        connect(mapStateToProps, null)(
            ManagerDocsComponent));
