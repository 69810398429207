import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'
import { getBuilding } from './../actions/data-manager'
import IssueExpansionControl from './IssueExpansion'

class TenantIssues extends Component {

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        this.props.dispatch(getBuilding(this.props.buildingId))
    }

    render() {
        let issues = this.props.issues || []
        return <Container>
            {issues.map(issue => <IssueExpansionControl issue={issue} key={issue.title} />)}
        </Container>
    }
}

function mapStateToProps(state) {
    return {
        buildingId: state.tenantBuildingId,
        issues: state.tenantIssues
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, null)(
            TenantIssues));

