import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, MenuItem, InputLabel } from '@material-ui/core'
import { genericStateChange } from './../actions'
import { withTranslation } from 'react-i18next'

class TenantUnitSelectorComponent extends Component {
    render() {
        let { t } = this.props
        let comboIndex = (this.props.selectedUnitIndex) || 0
        return (
            <>
                <br />
                <InputLabel htmlFor="user">{t("Unit")}</InputLabel>
                <Select
                    name="unit"
                    //?lableid, style
                    value={comboIndex}
                    onChange={
                        (event) => {
                            this.props.genericStateChange("selectedUnitIndex", event.target.value.toString())
                        }}
                >
                    {
                        this.props.unitNames.map((unitName, ix) => (

                            <MenuItem value={(ix + 0).toString()} key={ix} >{unitName}</MenuItem>
                        ))
                    }
                </Select>
            </>
        )
    }
}

function mapStateToProps(state) {
    let allUnits = (state.tenantBuilding ? state.tenantBuilding.units : undefined) || []
    let unitsIds = state.tenantUnits || []
    let unitNames = allUnits.length ?
        unitsIds.map(unitId => allUnits.find(unit => unit._id === unitId).address)
        :
        []
    return {
        selectedUnitIndex: state.selectedUnitIndex,
        unitNames
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, { genericStateChange })(
            TenantUnitSelectorComponent));
