import React from 'react'
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Button, Container } from '@material-ui/core';
import { PUBLIC_KEY, PRIVATE_KEY } from './TenantInfo/TenantInfo';
// import copy from 'copy-text-to-clipboard'
// import FileCopyIcon from '@material-ui/icons/FileCopy'



function TenantUtil(props) {
    const { t } = useTranslation();
    const { dispatch } = props
    return <Container>
        <br/>
        <Button
            variant="contained"
            color="primary"
            onClick={e => {
                if (window.confirm("Are you sure you want to delete key")) {
                    localStorage.removeItem(PUBLIC_KEY)
                    localStorage.removeItem(PRIVATE_KEY)
                }
            }}>
            {t('Delete key')}
        </Button>
    </Container>

    return

}

export default connect(null, null)(TenantUtil);