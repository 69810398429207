import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Select, { Option } from '@material/react-select';
import { InputLabel, Select, MenuItem, FormControl, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


export default function UserDialog(props) {

    const [selectedUser, setSelectedUser] = React.useState(props.user)
    const [isRegisteredOwner, setIsRegisteredOwner] = React.useState(true)
    const [firstName, setFirstName] = React.useState(props.firstName)
    const [lastName, setLastName] = React.useState(props.lastName)
    const { t } = useTranslation();
    useEffect(() => {
        setSelectedUser(props.user)
    }, [props.user])

    console.log(props.user)
    console.log(`selected user: ${selectedUser}`)
    return (
        <Dialog
            open={props.open}
        //fullWidth
        >
            <DialogTitle id="form-dialog-title">{t("Assign owner")}</DialogTitle>
            <DialogContent >
                <TextField label={t("First name")} value={firstName} onChange={e => setFirstName(e.target.value)} />
            </DialogContent>
            <DialogContent>
                <TextField label={t("Last name")} value={lastName} onChange={e => setLastName(e.target.value)} />
            </DialogContent>
            <DialogContent>
                <FormControlLabel
                    control={
                        <Switch checked={isRegisteredOwner} color="primary" onChange={(e) => {
                            setIsRegisteredOwner(!isRegisteredOwner)
                        }} />
                    }
                    label={t("Is owner registered")}
                />
            </DialogContent>
            {isRegisteredOwner && <DialogContent
                style={{ minWidth: 220 }}
            >
                <FormControl  >  {/*COULD NOT SET WORKING variant="outlined"*/}
                    {/* <InputLabel id="user-select"   >Registered user</InputLabel> */}
                    <InputLabel htmlFor="user-select"   >{t("Registered user")}</InputLabel>
                    <Select
                        // variant="outlined"
                        name="user"
                        style={{ minWidth: 180 }}
                        value={selectedUser}
                        onChange={(event) => {
                            setSelectedUser(event.target.value)
                            setFirstName(props.users[event.target.value].firstName)
                            setLastName(props.users[event.target.value].lastName)
                        }}
                    >
                        {
                            props.users && Object.keys(props.users).map(
                                k => <MenuItem value={props.users[k].user} key={props.users[k].user} >{props.users[k].firstName + " " + props.users[k].lastName}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>

            </DialogContent>}
            <DialogActions>
                <Button onClick={props.handleCancel} color="primary">{t("Cancel")}</Button>
                <Button onClick={() => {
                    if (isRegisteredOwner) {
                        props.handleUpdate(selectedUser, firstName, lastName)
                    } else {
                        props.setOwner(firstName, lastName)
                    }
                }} disabled={!selectedUser && isRegisteredOwner} color="primary">{t("Assign")}</Button>
            </DialogActions>
        </Dialog>
    );
}