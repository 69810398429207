import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'

// import logo from './logo.svg';
//TODO: is needed this, already loaded
import './../App.css';

import BuildingList from './BuildingList.jsx'
import BuildingEditor from './BuildingEditor.jsx'
import TemplateGenetrator from './Templates.jsx'
import WorkingInvoice from './WorkingInvoice.jsx'
import Notifications from './Notifications.jsx'
import Temp from './Temp.jsx'
import ManagerIssues from './ManagerIssues.jsx'
import ManagerVoting from './ManagerVoting/ManagerVoting.jsx'
import Units from './Units'
import ManagerInvoices from './ManagerInvoices/ManagerInvoices.jsx';
import ManagerBuildingInflows from './ManagerBuildingInflows.jsx';
import NewManager from "./NewManager.jsx";

import '@material/react-top-app-bar/dist/top-app-bar.css';
import '@material/react-material-icon/dist/material-icon.css';
import "@material/react-drawer/dist/drawer.css";
import "@material/react-list/dist/list.css"

import TopAppBar, {
    TopAppBarFixedAdjust,
    TopAppBarIcon,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarTitle,
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';

import Drawer, { DrawerAppContent, DrawerContent, DrawerHeader, DrawerTitle, DrawerSubtitle } from '@material/react-drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Route, Redirect, Switch, Link } from "react-router-dom";
import ManagerBuildingFinance from './ManagerBuildingFinance';
import ManagerDocs from './ManagerDocs';
import { JWT_MANAGER } from '../actions/network-manager';
import Users from './Users';
import { withStyles } from '@material-ui/core';

const styles = {
    logo: {
        display: "flex", 
        justifyContent: 'center'
    }
};

const APP = "/manager"

const Menu = [
    {
        label: "Buildings",
        pathname: APP + "/buildings",
    },
    {
        label: "New building",
        pathname: APP + "/new-building"
    },
    // {
    //     label: "Users",
    //     pathname: APP + '/users'
    // },
    {
        label: "Units",
        pathname: APP + "/units"
    },
    {
        label: "Notifications",
        pathname: APP + "/notifications"
    },
    {
        label: "Activities issues",
        pathname: APP + "/issues"
    },
    {
        label: "Activities voting",
        pathname: APP + "/voting"
    },
    {
        label: "Finance new invoice",
        pathname: APP + "/finance/new-invoice"
    },
    {
        label: "Finance invoices",
        pathname: APP + "/finance/invoices"
    },
    {
        label: "Building finance",
        pathname: APP + "/finance/building-invoices"
    },
    {
        label: "Building inflows",
        pathname: APP + "/finance/building-inflows"
    },
    {
        label: "Documents Links",
        pathname: APP + "/docs"
    },
    // {
    //     label: "Documents Templates",
    //     pathname: APP + "/templates"
    // }
];

class ManagerApp extends Component {
    //TODO: move language to global state
    state = { selectedIndex: Menu.findIndex(item=>item.pathname === window.location.pathname), open: false, english: false};

    onRouteChanged() {
        // this.setState({});
        console.log("ROUTE CHANGED ");
    }

    render() {
        let { t, i18n, unauthenticated, classes } = this.props

        // console.log("Render manager, unauth " + unauthenticated);
        return (
            // TODO: remove onRouteChanged
            unauthenticated ?
                <Redirect to="/login-manager" />
                :
                <>
                    <Drawer
                        modal
                        // dismissible
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}
                    >
                        {/* <DrawerHeader>
                            <DrawerTitle tag='h1'>
                                Moj sprat
            </DrawerTitle>
                            <DrawerSubtitle>
                                selected building: {this.props.selectedBuildingIndex} - {this.state.selectedIndex}
                            </DrawerSubtitle>
                        </DrawerHeader> */}
                        <DrawerContent>
                        <List onClick= {() => {this.setState({open:false})}}>
                            {/*
                            Swapped <Link> with <ListItem> (handleSelect calling history.push()) to fix the issue:
                            https://gitlab.coinkernel.com/coin-kernel/concept-front-mojsprat/issues/2 */}

                            {Menu.map((item) => (
                                <ListItem className="menu" component={Link} to={item} key={item.label}>
                                    <ListItemText primary={t(item.label)} />
                                </ListItem>
                            ))}
                                <ListItem button onClick={
                                    (e) => {
                                        if (this.state.english) {
                                            i18n.changeLanguage("sr")
                                        } else {
                                            i18n.changeLanguage("en")
                                        }
                                        this.setState({
                                            english: !this.state.english
                                        })
                                        e.stopPropagation()
                                    }
                                } >

                                    <ListItemText primary={this.state.english ? 'Srpski' : 'English'} />
                                </ListItem>
                                <ListItem button onClick={
                                    (e) => {
                                        localStorage.removeItem(JWT_MANAGER)
                                        //TODO: User router, clean data
                                        window.location.href = "/login-manager"
                                        e.stopPropagation()
                                    }
                                } >
                                    <ListItemText primary={t('Logout')} />
                                </ListItem>
                            </List>
                        </DrawerContent>
                    </Drawer>
                    <DrawerAppContent >
                        <TopAppBar >
                            <TopAppBarRow>
                                <TopAppBarSection align='start'>
                                    <TopAppBarIcon navIcon tabIndex={0}>
                                        <MaterialIcon hasRipple icon='menu' onClick={() => this.setState({ open: !this.state.open })} />
                                    </TopAppBarIcon>
                                </TopAppBarSection>
                                <TopAppBarSection align='center' className={classes.logo}>
                                    {/* <TopAppBarTitle>Moj Sprat</TopAppBarTitle> */}
                                        <img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt='logo'/>
                                </TopAppBarSection>
                                <TopAppBarSection align='end' role='toolbar'>
                                    {/* <TopAppBarIcon actionItem tabIndex={0}>
                                        <MaterialIcon
                                            aria-label="print page"
                                            hasRipple
                                            icon='print'
                                            onClick={() => console.log('print')}
                                        />
                                    </TopAppBarIcon> */}
                                </TopAppBarSection>
                            </TopAppBarRow>
                        </TopAppBar>
                        <TopAppBarFixedAdjust>
                            <Switch>
                                <Route path={APP + "/new-building"} component={BuildingEditor} />
                                <Route path={APP + "/buildings"} component={BuildingList} />
                                <Route path={APP + "/units"} component={Units} />
                                {/* <Route path={APP + "/users"} component={Users} /> */}
                                <Route path={APP + "/notifications"} component={Notifications} />
                                <Route path={APP + "/temp/:index"} component={Temp} />
                                <Route path={APP + "/issues"} component={ManagerIssues} />
                                <Route path={APP + "/voting"} component={ManagerVoting} />
                                <Route path={APP + "/docs"} component={ManagerDocs} />
                                {/* <Route path={APP + "/templates"} component={TemplateGenetrator} /> */}
                                <Route path={APP + "/finance/new-invoice"} component={WorkingInvoice} />
                                <Route path={APP + "/finance/invoices"} component={ManagerInvoices} />
                                <Route path={APP + "/finance/building-invoices"} component={() => <ManagerBuildingFinance />} />
                                <Route path={APP + "/finance/building-inflows"} component={() => <ManagerBuildingInflows />} />
                                <Route path={APP + "/new_mng"} component={() => <NewManager />} />
                                <Route >
                                    <Redirect to={APP + "/finance/invoices"} />
                                </Route>
                            </Switch>
                        </TopAppBarFixedAdjust>
                    </DrawerAppContent>
                </>
        );
    }
}

function mapStateToProps(state) {
    return {
        unauthenticated: state.unauthenticated,
        selectedBuildingIndex: state.selectedBuildingIndex
    }
}

export default
    withStyles(styles)(
        withTranslation()(
            connect(mapStateToProps, null)(
                ManagerApp)
        )
    );