import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from '@material/react-layout-grid';

import { fetchBuildings, postInflow } from './../actions/data-manager'

import { withTranslation } from "react-i18next";
import BuildingSelector from "./BuildingSelector";
import { Button, TableCell, Table, TableHead, TableRow, TableBody } from "@material-ui/core";


class ManagerBuildingInflows extends Component {

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        this.props.dispatch(fetchBuildings())
    }



    actionRenderCallback = (inflow) => {
        //TODO: add translation
        let { t } = this.props
        return <>
            <Button size="small" color="primary" variant="contained" onClick={e => {
                if (window.confirm(`${t('Revert the payment')}: \r\n${t('Payment ID')}: ${inflow.invoiceId} \r\n${t('Unit')}: ${inflow.unit} \r\n${t('Date')}: ${inflow.date}`)) {
                    this.props.dispatch(postInflow(this.props.selectedBuilding, inflow.invoiceId, undefined, -inflow.amount))
                }
            }}>
                {t("Revert")}
        </Button>
        </>
    }


    render() {
        let { t } = this.props

        let inflows = (this.props.building && this.props.building.finance && this.props.building.finance.inflow) || [];
        let units = this.props.building && this.props.building.units;
        inflows = inflows.map(inflow => ({ ...inflow, unit: units[inflow.invoiceId.split('-')[1]].address }))

        return <>
            <Grid>
                <Grid>
                <BuildingSelector />
                </Grid>
                <Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell >{t("Date")}</TableCell>
                                <TableCell >{t("Invoice Id")}</TableCell>
                                <TableCell >{t("Unit")}</TableCell>
                                <TableCell >{t("Amount")}</TableCell>
                                <TableCell >{t("Actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inflows.map(inflow => (
                                <TableRow key={inflow._id}>
                                    {}
                                    <TableCell >{t("dt", { date: new Date(inflow.date) })} </TableCell>
                                    <TableCell >{inflow.invoiceId} </TableCell>
                                    <TableCell >{inflow.unit} </TableCell>
                                    <TableCell >{t("priceFormat", { num: inflow.amount })} </TableCell>
                                    <TableCell >{this.actionRenderCallback(inflow)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </>
    }
}

function mapStateToProps(state) {
    return {
        building: state.buildings[state.selectedBuildingIndex] || {},
        selectedBuilding: state.selectedBuilding,
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, null)(
            ManagerBuildingInflows));