import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withTranslation } from 'react-i18next'

import {  getBuilding } from './../actions/data-manager'

import { Card, CardHeader, CardContent, Typography } from '@material-ui/core'


class TenantNotificationComponent extends Component {
    // constructor(props) {
    //     super(props);
    // }

    handleGenericChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    componentDidMount() {
        //TODO: handle connection errors
        this.props.dispatch(getBuilding(this.props.buildingId))
    }

    render() {
        let notifications = this.props.notifications || []

        return (
            <Container >
                {
                    notifications.map(notification => (
                        <React.Fragment key={notification.title}>
                            <p />
                            <Card  >
                                <CardHeader title={notification.title} />
                                <CardContent>
                                    <Typography  style={{whiteSpace: 'pre-wrap'}} >{notification.description}</Typography>
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    ))
                }
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        buildingId: state.tenantBuildingId,
        notifications: state.tenantNotifications
    }
}

export default
    withTranslation()(connect(mapStateToProps, null)(TenantNotificationComponent));
