import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

//For editable control
import { ExpansionPanelActions, Button, Tooltip, IconButton } from '@material-ui/core';

export default function IssueExpansionControl(props) {
    const { t } = useTranslation();

    return <ExpansionPanel >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {`${props.issue.title} (${props.issue.solutions.length})`}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails >
            <Typography variant="caption">
                {props.issue.description}
            </Typography>
        </ExpansionPanelDetails>
        {props.isEditable &&
            <ExpansionPanelActions>
                <Button size="small" color="primary" onClick={e => { props.onMoveUpIssue({issueIndex: props.issueIx }) }}>{t("Move Up")}</Button>
                {/* <Button size="small" color="primary" onClick={e => { props.onMoveDownIssue({issueIndex: props.issueIx }) }}>Move Down</Button> */}
                <Button size="small" color="primary" onClick={e => { props.onEditIssue({issueIndex: props.issueIx }) }}>{t("Edit issue")}</Button>
                <Button size="small" color="primary" onClick={e => { props.onDeleteIssue({issueIndex: props.issueIx }) }}>{t("Delete issue")}</Button>
                <Button size="small" color="primary" onClick={e => { props.onAddSolution({ solution: {}, isIndex: props.issueIx }) }}>{t("Add solution")}</Button>
                <Button size="small" color="primary" onClick={e => { props.onStartVoting() }} disabled={props.issue.solutions.length < 2}>{t("Prepare Voting")}</Button>
            </ExpansionPanelActions>
        }
        {props.issue.solutions.map((solution, solIndex) => {
            return <React.Fragment key={solution.name}>
                <Divider />
                <ExpansionPanelDetails >
                    <Typography variant="body2" >
                        {t("offer") + ": " + solution.name + " " + t("price") + ": " + solution.price}
                    </Typography>
                    {props.isEditable &&
                        <div style={{marginLeft: "auto"}}>
                            {/* <Button size="small" color="primary">Remove Solution</Button> */}
                            {/* <Button size="small" color="primary" onClick={e => { props.onEditSolution({ solution, solIndex, isIndex: props.issueIx }) }}>{t("Edit Solution")}</Button> */}
                            <Tooltip title={t("Edit Solution")}>
                                <IconButton
                                color="primary" 
                                style={{paddingBlockStart: "0px"}}
                                onClick= {e => { 
                                    props.onEditSolution({ solution, solIndex, isIndex: props.issueIx }) 
                                }}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Delete solution")}>
                                <IconButton
                                style={{paddingBlockStart: "0px"}}
                                onClick= {e => {
                                    if (window.confirm(t("Are you sure?"))) {
                                        props.handleDeleteSolution({ solution, issueIndex: props.issueIx }) 
                                    }
                                }}
                                >
                                    <DeleteIcon color="error"/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                </ExpansionPanelDetails>
                <ExpansionPanelDetails >
                    <Typography variant="caption">
                        {solution.description ? solution.description : t("Solution description has not been set.")}
                    </Typography>
                </ExpansionPanelDetails>
            </React.Fragment>
        })}
    </ExpansionPanel>
}
