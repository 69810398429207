import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { Grid } from '@material/react-layout-grid';
import Button from '@material-ui/core/Button';
import TextField, { Input } from '@material/react-text-field';
import Paper from '@material-ui/core/Paper'
import { withTranslation } from 'react-i18next'

import BuildingSelector from './BuildingSelector'
import { postNewIsssue, postNewSolution, fetchBuildings, editSolution, editIssue, deleteIssue, moveUpIssue, deleteSolution } from './../actions/data-manager'
import IssueExpansionControl from "./IssueExpansion";
import SolutionDialog from './SolutionDialog'
import IssueDialog from './IssueDialog'
import { Redirect } from "react-router-dom";
import { WORKING_VOTING } from "./ManagerVoting/ManagerVoting";


//TODO: consider TextField counter
// import {MDCTextFieldCharacterCounter} from '@material/textfield/character-counter';
// const characterCounter = new MDCTextFieldCharacterCounter(document.querySelector('.mdc-text-field-character-counter'));


class IssuesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: "",
            openSolution: false,
            solutionBundle: { solution: null, solIndex: -1 },
            openIssue: false,
            issue: { name: "", description: "" },
            redirecting: false
        }
    }

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        this.props.dispatch(fetchBuildings())
    }

    handleCancel = () => {
        this.setState({ openSolution: false })
    };

    handleUpdate = (updatedSolutionBundle) => {
        this.props.dispatch(editSolution(this.props.selectedBuilding, updatedSolutionBundle.isIndex, updatedSolutionBundle.solIndex, updatedSolutionBundle.solution))
        //TODO: handle error
        //TODO: set state in callback
        this.setState({ openSolution: false })
    };

    onEditSolution = issueIx => (solutionBundle) => {
        //TODO consider using issueIX, do not share index data with dialog
        this.setState({ openSolution: true, dialogType: "update", solutionBundle })
    }

    onAddSolution = issueIx => (solutionBundle) => {
        //TODO consider using issueIX, do not share index data with dialog
        this.setState({ openSolution: true, dialogType: "add", solutionBundle })
    }

    handleAddSolution = (updatedSolutionBundle) => {
        this.props.dispatch(postNewSolution(
            this.props.selectedBuilding,
            updatedSolutionBundle.isIndex,
            updatedSolutionBundle.solution.name,
            updatedSolutionBundle.solution.description,
            updatedSolutionBundle.solution.price,
            () => { }
        ))
        //TODO: handle error
        //TODO: set state in callback
        this.setState({ openSolution: false })
    };

    handleDeleteSolution = (deleteSolutionBundle) => {
        
        let data = {
            issueIndex: deleteSolutionBundle.issueIndex,
            name: deleteSolutionBundle.solution.name,
            description: deleteSolutionBundle.solution.description,
            price: deleteSolutionBundle.solution.price
        }
        
        this.props.dispatch(deleteSolution(
            this.props.selectedBuilding,
            data
        ))

        this.setState({ openSolution: false })
    };

    onStartVoting = issueIx => () => {

        let building = this.props.bld[this.props.selectedBuildingIndex] || {}
        let issues = building.issues || []
        let solutions = issues[issueIx].solutions
        let subject = issues[issueIx].title
        let description = issues[issueIx].description
        let start = new Date()
        let end = new Date()
        let candidates = solutions.map(sol => { return { name: sol.name } })
        this.saveWorkingVoting(building._id, {subject, description, candidates, start, end});
        this.setState({ redirecting: true })
    }

    handleGenericChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onEditIssue = issueIx => () => {
        let building = this.props.bld[this.props.selectedBuildingIndex] || {}
        let issues = building.issues || []
        this.setState({ openIssue: true, dialogType: "update", issue: issues[issueIx], issueIndex: issueIx })
    }

    onDeleteIssue = issueIx => () => {
        let building = this.props.bld[this.props.selectedBuildingIndex] || {}
        let issues = building.issues || []
        this.setState({ openIssue: true, dialogType: "delete", issue: issues[issueIx], issueIndex: issueIx })
    }

    handleCancelIssue = () => {
        this.setState({ openIssue: false })
    };

    handleUpdateIssue = (issue) => {
        this.props.dispatch(editIssue(this.props.selectedBuilding, this.state.issueIndex, issue))
        //TODO: handle error
        this.setState({ openIssue: false })
    };

    handleDeleteIssue = () => {
        let building = this.props.bld[this.props.selectedBuildingIndex] || {}
        let issues = building.issues || []
        this.props.dispatch(deleteIssue(this.props.selectedBuilding, issues[this.state.issueIndex]._id))
        //TODO: handle error
        this.setState({ openIssue: false })
    };

    onMoveUpIssue = issueIx => () => {
        let building = this.props.bld[this.props.selectedBuildingIndex] || {}
        let issues = building.issues || []
        let issueId = issues[issueIx]._id
        this.props.dispatch(moveUpIssue(this.props.selectedBuilding, issueIx, issueId))
    }

    saveWorkingVoting(buildingId, votingObject) {
        localStorage.setItem(WORKING_VOTING+buildingId, JSON.stringify(votingObject));
    }

    render() {
        let { t } = this.props
        let building = (this.props.bld||[])[this.props.selectedBuildingIndex] || {}
        let issues = building.issues || []
        let { title } = this.state
        let { description } = this.state

        if (this.state.redirecting) {
            return <Redirect to="/manager/voting" />
        }

        return (
            <Container>
                <BuildingSelector />
                {/* TODO: Check if "this.state.openSolution &&" creates client delay due to duplicated render handling */}
                {this.state.openSolution && <SolutionDialog
                    open={this.state.openSolution}
                    type={this.state.dialogType}
                    solutionBundle={this.state.solutionBundle}
                    handleCancel={this.handleCancel}
                    handleUpdate={this.handleUpdate}
                    handleAdd={this.handleAddSolution}
                />}
                {this.state.openIssue && <IssueDialog
                    open={this.state.openIssue}
                    type={this.state.dialogType}
                    issue={this.state.issue}
                    handleCancel={this.handleCancelIssue}
                    handleUpdate={this.handleUpdateIssue}
                    handleDelete={this.handleDeleteIssue}
                />}
                {issues.map((issue, issIx) => <IssueExpansionControl issue={issue} key={issue.title} isEditable={true}
                    onEditSolution={this.onEditSolution(issIx)}
                    onAddSolution={this.onAddSolution(issIx)}
                    handleDeleteSolution={this.handleDeleteSolution}
                    onStartVoting={this.onStartVoting(issIx)}
                    onEditIssue={this.onEditIssue(issIx)}
                    onDeleteIssue={this.onDeleteIssue(issIx)}
                    onMoveUpIssue={this.onMoveUpIssue(issIx)}
                    issueIx={issIx} />)}
                {/* <Paper> */}
                <Paper>
                    <Grid>
                        <TextField label={t("Title")} outlined             >
                            <Input
                                name="title"
                                type="text"
                                value={title}
                                onChange={this.handleGenericChange}
                            />
                        </TextField>
                    </Grid>
                    <Grid>
                        <TextField textarea outlined  fullWidth>
                            <Input
                                placeholder={t("Description")}
                                name="description"
                                type="text"
                                value={description}
                                onChange={this.handleGenericChange}
                            />
                        </TextField>
                    </Grid>
                    <Grid>
                        <Button variant="contained" color="primary" onClick={
                            (e) => {
                                e.preventDefault();
                                this.props.dispatch(postNewIsssue(this.props.selectedBuilding, this.state.title, this.state.description
                                    , () => this.setState({ title: "", description: "" })
                                ))
                                //TODO only on success do this
                            }}>
                            {t("Add Issue")}
                        </Button>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedBuilding: state.selectedBuilding,
        selectedBuildingIndex: state.selectedBuildingIndex,
        bld: state.buildings,
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, null)(
            IssuesComponent));
