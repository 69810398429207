import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { Grid } from '@material/react-layout-grid';

import { getBuilding } from './../actions/data-manager'

import TenantUnitSelector from './TenantUnitSelector'

import { Table, TableHead, TableRow, TableCell, TableBody, Divider, IconButton, Tooltip } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { withTranslation } from "react-i18next";
import copy from 'copy-text-to-clipboard'
import FileCopyIcon from '@material-ui/icons/FileCopy'

function CopyableText(props) {
    return <div>
        <Typography style={{ paddingRight: "5px" }} display="inline" variant="body2" >{props.label}</Typography>
        <span style={{ border: "double", padding: "5px" }} >{props.text}</span>
        <Tooltip title={"Kopiraj"} arrow={"true"} >
            <IconButton onClick={() => { copy(props.text) }} >
                <FileCopyIcon fontSize="small" color={"primary"} />
            </IconButton>
        </Tooltip>
    </div>


}

class InvoiceListComponent extends Component {

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        // this.props.dispatch(fetchBuildings())
        this.props.dispatch(getBuilding(this.props.buildingId))
    }

    render() {
        let { t } = this.props
        let building = this.props.building || {}
        let units = building.units || []
        let unit = units.find(x => x._id === this.props.unitId) || {}
        let invoices = unit.invoices || []
        let bankAccount = (building.finance || {}).bankAccount
        let lastUnpaid = invoices.find(x => x.status !== "paid") || {}
        let payReference = lastUnpaid.invoiceId || (invoices[invoices.length - 1] || {}).invoiceId
        // let hasMultipleInvoices = this.props.tenantUnits.length > 1
        return (
            <Container>
                <TenantUnitSelector />
                <Grid>
                    {unit.debit !== undefined && <Typography variant="body2" >
                        {unit.debit > 0 ? t("Debit") + ": " + t("priceFormat", { num: unit.debit }) :
                            t("Credit") + ": " + t("priceFormat", { num: - unit.debit })}
                    </Typography>
                    }
                    {/* {
                        hasMultipleInvoices &&
                        <Typography variant="body2" >
                            {t("Debit") + ": " + t("priceFormat", { num: unit.debit })}
                        </Typography>
                    } */}

                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell >{t("Invoice Id")}</TableCell>
                            <TableCell >{t("Period")}</TableCell>
                            <TableCell >{t("Total")}</TableCell>
                            <TableCell >{t("Status")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map(invoice => (
                            <TableRow key={invoice.invoiceId}>
                                {}
                                <TableCell >{invoice.invoiceId}</TableCell>
                                <TableCell >{t("dt", { date: new Date(invoice.periodStart) }) + " -" + t("dt", { date: new Date(invoice.periodEnd) })} </TableCell>
                                <TableCell >{t("priceFormat", { num: invoice.total })} </TableCell>
                                <TableCell >{t(invoice.status)} </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <br />
                <br />
                <br />
                <Divider />
                <div style={{ textAlign: "right", float: "right" }} >
                    <CopyableText label={t("bill from")} text={building.name} />
                    <CopyableText label={t("amount")} text={t("priceFormat", { num: (unit.debit > 0 ? unit.debit : 0) })} />
                    <CopyableText label={t("account")} text={bankAccount} />
                    <CopyableText label={t("invoice no")} text={payReference} />
                </div>


            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        buildingId: state.tenantBuildingId,
        building: state.tenantBuilding,
        unitId: (state.tenantUnits||[])[state.selectedUnitIndex]
    }
}


export default
    withTranslation()(
        connect(mapStateToProps, null)(
            InvoiceListComponent));
