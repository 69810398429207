import React, { Component } from 'react'
import { connect } from "react-redux";
import { changeNewUnit, changeNewUnitOwner } from '../actions';
import { withTranslation } from 'react-i18next'

import TextField, { Input } from '@material/react-text-field';
import '@material/react-text-field/dist/text-field.css';

// import {MenuItem, Select } from '@material-ui/core';
import Select, { Option } from '@material/react-select';

// import '@material/react-list/dist/menu.css';
// import '@material/react-menu-surface/dist/menu.css';
// import '@material/react-menu/dist/menu.css';
import '@material/react-select/dist/select.css';

import Switch from '@material/react-switch';
import "@material/react-switch/dist/switch.css";

import Card, {
    CardPrimaryContent,
    // CardMedia,
    // CardActions,
    // CardActionButtons,
    // CardActionIcons
} from "@material/react-card";
import '@material/react-card/dist/card.css';

import { /*Cell,*/ Grid, Row } from '@material/react-layout-grid';
import '@material/react-layout-grid/dist/layout-grid.css';


/*
TODO:
Owner Ref 
Tenant Ref
First name
Last name
Phone
Email
ID [Unique check] JMBG only

*/

class Unit extends Component {
    // constructor(props) {
    //     super(props);
    // }

    // handleGenericChange = (event) => { this.setState({ [event.target.name]: event.target.value }); }
    handleGenericChange = (event) => { this.props.changeNewUnit({ id: this.props.id, key: event.target.name, value: event.target.value }); }
    handleSizeChange = (event) => { this.props.changeNewUnit({ id: this.props.id, key: event.target.name, value: event.target.valueAsNumber }); }
    handleCheckedChange = (event) => { this.props.changeNewUnit({ id: this.props.id, key: "checked", value: event.target.checked }) }
    handleOwnerChange = (event) => { this.props.changeNewUnitOwner({ id: this.props.id, key: event.target.name, value: event.target.value }) }

    onEnhancedChange = (index, item) => (
        this.setState({ type: item.getAttribute('data-value') })
    );

    render() {
        let { t } = this.props
        let { id } = this.props
        let { address, type, size, checked, owner, tenant } = this.props.units[id]
        let { firstName, lastName } = owner

        return (
            <Card>
                <CardPrimaryContent>
                    <Grid>
                        <h1>{t("Unit") + " " + (id + 1)}</h1>
                    </Grid>
                    <Grid>
                        <TextField label={t("Address")} >
                            <Input
                                name="address"
                                type="text"
                                value={address}
                                onChange={this.handleGenericChange}
                            />
                        </TextField>

                        <Select
                            label={t("Type")}
                            name="type"
                            value={type}
                            onChange={this.handleGenericChange}
                        // onEnhancedChange={this.onEnhancedChange}

                        >
                            <Option value='flat'>{t("Flat")}</Option>
                            <Option value='garage'>{t("Garage")}</Option>
                            <Option value='other'>{t("Other")}</Option>
                        </Select>

                        <TextField label={t("Size")}>
                            <Input
                                name="size"
                                type="number"
                                value={size}
                                onChange={this.handleSizeChange}
                            />
                        </TextField>


                        <TextField label={t("First Name")}>
                            <Input
                                name="firstName"
                                type="text"
                                value={firstName}
                                onChange={this.handleOwnerChange}
                            />
                        </TextField>
                        <TextField label={t("Last Name")}>
                            <Input
                                name="lastName"
                                type="text"
                                value={lastName}
                                onChange={this.handleOwnerChange}
                            />
                        </TextField>
                        {/* <Row>
                            <Switch
                                label="ASDFFF"
                                name="checked"
                                nativeControlId='my-switch'
                                checked={checked}
                                onChange={this.handleCheckedChange} />
                        </Row> */}
                        {/* TODO add  <label htmlFor='my-switch'>My Switch</label> */}
                        {checked ?
                            <TextField label={t("Tenant")}>
                                <Input
                                    name="tenant"
                                    type="text"
                                    value={tenant}
                                    onChange={this.handleGenericChange}
                                />
                            </TextField>
                            : null}
                    </Grid>

                    {/* </p> */}

                </CardPrimaryContent>


            </Card>
        )
    }
}

function mapStateToProps(state) {
    //TODO: (low) consider ownProps binding
    return { units: state.newBuilding.units }
}

export default
    withTranslation()(connect(mapStateToProps, { changeNewUnit, changeNewUnitOwner })(Unit));