import React, { useRef } from 'react'
import { connect } from "react-redux";
import { TextField, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { thunkRequestManager } from '../actions/data-manager.js';


function NewManager(props) {
    const { t } = useTranslation();
    let { dispatch } = props
    const [username, setUsername] = React.useState('aca');
    const [password, setPassword] = React.useState('faca');
    const [email, setEmail] = React.useState('temppp');

    return <>
        {/* <TextField label /> */}

        <TextField
            variant="outlined"
            margin="normal"
            required
            id="username"
            label={t("Username")}
            name="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
        // error={"username" === errorField}
        // helperText={"username" === errorField?errorText:null}
        />

        <TextField
            variant="outlined"
            margin="normal"
            required
            id="email"
            label={t("Email")}
            name="email"
            // autoComplete="email"
            // autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
        // error={"email" === errorField}
        // helperText={"email" === errorField ? errorText : null}
        />
        <TextField
            variant="outlined"
            margin="normal"
            required
            id="password"
            label={t("Password")}
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
        />
        <Button
            variant="contained"
            color="primary"
            onClick={e => {
                if (window.confirm("Do you want to add new manager")) {
                    dispatch(thunkRequestManager(`/auth/register-manager`,
                        { username, password, email },
                        (err, res) => {
                            if (err) {
                                alert(err.errorMessage)
                            } else {
                                alert(res.message)
                            }
                        }
                    ))
                }
            }}>
            {'New Manager'}
        </Button>
    </>
}

export default connect(null, null)(NewManager)
