import { createStore, applyMiddleware } from 'redux'
import rootReducer, { parseTenantJwt, parseManagerJwt } from "../reducers/index";
import thunk from 'redux-thunk'

import { genericAction, changeSelectedBuilding, TOKEN_RECEIVED, TOKEN_RECEIVED_MANAGER } from '../actions';
import { JWT_MANAGER, JWT_TENANT } from '../actions/network-manager';


// const initialState = {
//     buildings: []
// }


// function logger({ getState }) {
//     return next => action => {
//        console.log('action', action);
//        const returnVal = next(action);
//        console.log('state when action is dispatched', getState()); 
//        return returnVal;
//     }
//  }

// let initialState = {
//     newBuilding : { buildingName: "Pozeska 4", unitCount: 0, units: [], startBalance: 0 }
// }

export const detectApp = () => {
    if (window.location.pathname.startsWith("/tenant")) { return "tenant"}
    if (window.location.pathname.startsWith("/manager")) { return "manager"}
    if (localStorage.getItem(JWT_MANAGER)) {
        return "manager"
    } else if (localStorage.getItem(JWT_TENANT)) {
        return "tenant"
    }
    return undefined
}

const detectedApp = detectApp()

let initState = detectedApp ?
    (detectedApp === "tenant" ?
        {...parseTenantJwt(localStorage.getItem(JWT_TENANT))}
        :
        {
            ...parseManagerJwt(localStorage.getItem(JWT_MANAGER))
            , selectedBuilding: localStorage.getItem("selectedBuildingId")
            , selectedBuildingIndex: localStorage.getItem("selectedBuildingIndex")
        }
    )
    :
    { unauthenticated: true}

initState = {...initState, detectedApp}

const store = createStore(
    rootReducer
    , {...initState}
    , applyMiddleware(thunk)
);

if (detectedApp === "manager" && localStorage.getItem("selectedBuildingId") && localStorage.getItem("selectedBuildingIndex")){
            store.dispatch(changeSelectedBuilding(localStorage.getItem("selectedBuildingId"), localStorage.getItem("selectedBuildingIndex")))
}

export default store;