import jwtDecode from 'jwt-decode'
import {
    ADD_BUILDING,
    CHANGE_NEW_BUILDING,
    CHANGE_NEW_UNIT_COUNT,
    CHANGE_NEW_UNIT,
    FETCH_ALL_BUILDING_SUCCESS,
    CHANGE_OWNER,
    CREATE_DOCUMENTS,
    CHANGE_SELECTED_BUILDING,
    GENERIC_STATE_CHANGE,
    TOKEN_RECEIVED,
    TENANT_BUILDING_RECEIVED,
    TOKEN_RECEIVED_MANAGER,
    UNAUTHENTICATED,
    TENANT_VOTING_RECEIVED
} from "../actions"

import { postNewBuilding, fetchTemplate } from './../actions/data-manager'

// import { building, building2 } from '../data'
// import { stat } from "fs";
// import { func } from "prop-types";

function createEmptyUnit(address) {
    // return { address: address, checked: false, type: "flat", size: 0, owner: { firstName: "Milos", lastName: "Markovic" } }
    return { address: address, checked: false, type: "flat", size: 0, owner: { firstName: "", lastName: "" } }
}

//TODO: remove this
const initialState = {
    // buildings: [{ name: "Pastroviceva 14" }, building, building2]
    buildings: []
    // , newBuilding: { buildingName: "Vojvode Putnika 12", unitCount: 1, units: [createEmptyUnit("")], startBalance: 0 }
    , newBuilding: { buildingName: "", unitCount: 1, units: [createEmptyUnit("")], startBalance: 0 }
}

export function parseTenantJwt(payload){
    try {
        let jwt = jwtDecode(payload)
        console.log(jwt)
        let tenantBuildingId = jwt.bl[0].id
        let tenantUnits = jwt.bl[0].units
        //NOTE: this could be removed or loaded from state
        let selectedUnitIndex = tenantUnits.length - 1
        return {
            tenantBuildingId,
            tenantUnits,
            selectedUnitIndex,
            unauthenticated: false
        }
    } catch (error) {
        console.log("JWT tenant parsing error")
        console.log(error)
        return {
            unauthenticated:true
        }
    }
}

export function parseManagerJwt(payload){
    try {
        let jwt = jwtDecode(payload)
        return {
            unauthenticated: false
        }
    } catch (error) {
        console.log("JWT parsing error")
        console.log(error)
        return {
            unauthenticated:true
        }
    }
}


function rootReducer(state = initialState, action) {
    // console.log (action);
    if (action.type === ADD_BUILDING) {
        //TODO: fix this, just a proof of concept, this must be handled as action
        postNewBuilding(action.payload)
        return Object.assign({}, state, {
            buildings: state.buildings.concat(action.payload)
            , newBuilding: { buildingName: "", unitCount: 1, units: [createEmptyUnit("")], startBalance: 0 }
        });
    }
    if (action.type === CHANGE_NEW_BUILDING) {
        let newNewBuilding = Object.assign({}, state.newBuilding, {
            [action.payload.key]: action.payload.value
        })
        return Object.assign({}, state, {
            newBuilding: newNewBuilding
        })
        // return {
        //     ...state, newBuilding:{
        //         ...state.newBuilding,
        //         buildingId : action.payload
        //     }
        // }
    }
    if (action.type === CHANGE_NEW_UNIT_COUNT) {
        //TODO: optimization hint, push or pop unit to array

        let cnt = action.payload
        if (cnt < 0) {
            //TODO: add error here to user
            return state;
        }
        let buildingName = state.newBuilding.buildingName

        // let emptyUnitsArray = [...Array(cnt)].fill().map((e, i) => { ..
        let unitsArray = []
        for (let i = 0; i < cnt; i++) {
            unitsArray.push(createEmptyUnit(
                    /* prefix */ buildingName +
                    /* slash for digits ended address */('0123456789'.indexOf(buildingName.slice(-1)) !== -1 ? "/" : " ") +
                    /* count */ (i + 1)
            ))
        }

        let newNewBuilding = Object.assign({}, state.newBuilding, {
            units: unitsArray
        })

        return Object.assign({}, state, {
            newBuilding: newNewBuilding
        })
    }
    if (action.type === CHANGE_NEW_UNIT) {
        //TODO: cleanup this, use more compact solution
        //TODO: add more reducers
        let updatedUnit = Object.assign(
            {},
            state.newBuilding.units[action.payload.id],
            { [action.payload.key]: action.payload.value }
        )
        let updatedArray = state.newBuilding.units.slice(0)
        updatedArray[action.payload.id] = updatedUnit
        let newNewBuilding = Object.assign({}, state.newBuilding, {
            units: updatedArray
        })
        return Object.assign({}, state, {
            newBuilding: newNewBuilding
        })
    }

    if (action.type === CHANGE_OWNER) {
        return {
            ...state,
            newBuilding: {
                ...state.newBuilding,
                units: state.newBuilding.units.map(
                    (item, index) => {
                        if (index !== action.payload.id) {
                            return item
                        }
                        return {
                            ...item,
                            owner: {
                                ...item.owner,
                                [action.payload.key]: action.payload.value
                            }
                        }
                    })
            }
        }
    }

    if (action.type === FETCH_ALL_BUILDING_SUCCESS) {
        return {
            ...state,
            buildings: action.payload.buildings
        }
    }

    if (action.type === CREATE_DOCUMENTS) {
        fetchTemplate(action.payload.buildingId)
        return state
    }

    if (action.type === CHANGE_SELECTED_BUILDING) {
        return {
            ...state,
            selectedBuilding: action.payload.selectedBuilding,
            selectedBuildingIndex: action.payload.selectedBuildingIndex
        }
    }

    if (action.type === GENERIC_STATE_CHANGE) {
        console.log("generic state change: " + action.payload.key + ":" + action.payload.value)
        return {
            ...state,
            [action.payload.key]: action.payload.value
        }
    }

    if (action.type === TOKEN_RECEIVED) {
        let jwtState = parseTenantJwt(action.payload)
        return {...state, ...jwtState}
    }

    if (action.type === TOKEN_RECEIVED_MANAGER) {
        let jwtState = parseManagerJwt(action.payload)
        return {...state, ...jwtState}
    }

    if (action.type === UNAUTHENTICATED) {
        //NOTE: this handles tenant and manager app
        let unauthenticated = true
        return {
            ...state,
            unauthenticated
        }
    }

    if (action.type === TENANT_BUILDING_RECEIVED) {
        let building = action.payload
        let tenantNotifications = building.notifications
        let tenantIssues = building.issues
        return {
            ...state,
            tenantNotifications,
            tenantBuilding: building,
            tenantIssues
        }
    }

    if (action.type === TENANT_VOTING_RECEIVED) {
        let tenantVoting = action.payload
        return { ...state, tenantVoting}
    }

    return state;
};

export default rootReducer;