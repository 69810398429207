import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { Grid } from '@material/react-layout-grid';
import Button from '@material-ui/core/Button';

import BuildingSelector from '../BuildingSelector'
import UnitSelector from '../UnitSelector'
import { downloadLatestInvoice, fetchBuildings, postInflow, settleUnsettled } from '../../actions/data-manager'

import { Table, TableHead, TableRow, TableCell, TableBody, Paper, Divider, Box } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { withTranslation } from "react-i18next";
import PaymentDialog from '../PaymentDialog'
import BalanceStats from "./BalanceStats";
import PaymentRegistration from "./PaymentRegistration";
import { withStyles } from '@material-ui/styles';


const styles = theme => ({
    root: {
        position: "relative",
    },
    paymentRegistration: {
        display: "flex",
        flexDirection: "column",
        gap: "50px"

    },
    printButton: {
        position: "absolute", 
        top: "10px", 
        right: "10px"
    },
    header: {
        alignSelf: "center", 
        fontWeight: "bold", 
        fontSize: "21px", 
        alignItems: "center"
    },
    unitSelector: {
        alignSelf: "flex-start", 
        display: "flex", 
        gap: "30px"
    },
    buttons: {
        display: "flex", 
        flexDirection: "row"
    }
})


class ManagerInvoicesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDialog: false
        }
    }

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        this.props.dispatch(fetchBuildings())
        //TODO: implment different call for Manager and Tenant ?!?!? 
        // this.props.dispatch(getTenentInvoices(this.props.buildingId))
        //this.props.dispatch(getTenentInvoices())
    }

    handleCancel = () => {
        this.setState({
            openDialog: false
        })
    };

    handleUpdate = (dataObject) => {
        this.props.dispatch(postInflow(this.props.selectedBuilding, dataObject.invoiceId, undefined, dataObject.amount))
        //TODO: handle error
        //TODO: set state in callback
        this.setState({ openDialog: false })
    };


    render() {
        let { t } = this.props
        let building = (this.props.bld||[])[this.props.selectedBuildingIndex] || {}
        let units = building.units || []
        let unit = units[this.props.selectedUnitIndex] || {}
        let invoices = unit.invoices || []

        let buildingPayable = building.finance && building.finance.payable && building.finance.payable.reduce((acc, x) => acc + x.price, 0)
        let inflowSum = building.finance && building.finance.inflow && building.finance.inflow.reduce((acc, x) => acc + x.amount, 0)
        let outflowSum = building.finance && building.finance.outflow && building.finance.outflow.reduce((acc, x) => acc + x.amount, 0)
        let sumFundsPayables = (building.finance && building.finance.fund && building.finance.fund.reduce((acc, x) => acc + (x.receivable || 0), 0 ) || 0)


        let fullBalance = building.finance && inflowSum && buildingPayable && (inflowSum - buildingPayable - sumFundsPayables)
        let cashBalance = building.finance && building.finance.balance
        let calculatedCashBalance = building.finance && (inflowSum - outflowSum)


        let defaultPaymentInvoice = invoices.length ? invoices.slice(-1)[0].invoiceId : ""
        let defaultPaymentAmount = invoices.length ? Math.round((unit.debit + Number.EPSILON) * 100) / 100 : ""

        let stats = {
            fullBalance: fullBalance,
            inflowSum: inflowSum,
            buildingPayable: buildingPayable,
            sumFundsPayables: sumFundsPayables,
            cashBalance: cashBalance,
            calculatedCashBalance: calculatedCashBalance
        };

        const { classes, theme } = this.props

        return (
            <Container>
                <BuildingSelector />
                { Object.keys(building).length !== 0 &&
                <Paper className={classes.root}>
                <Grid>
                    
                    {/* TODO Check why there was a conditional rendering totalBalance? */}
                    <BalanceStats stats={stats}/>
                </Grid>
                    <Divider/>

                    <Button  className={classes.printButton} disabled={this.props.selectedBuildingIndex === undefined || this.props.selectedBuildingIndex === -1} variant="contained" color="primary" onClick={
                        (e) => {
                            e.preventDefault();
                            // console.log(this.props.selectedBuilding)
                            this.props.dispatch(downloadLatestInvoice(this.props.selectedBuilding))
                        }}>
                        {t("Download latest invoice")}
                    </Button>

                <Grid>
                
                <Box className={classes.paymentRegistration}>
                <Typography className={classes.header}>{t("Payment Registration")}</Typography>
                <Box className={classes.unitSelector}>
                <UnitSelector />
                {unit.debit !== undefined && <Typography variant="body2">
                    {unit.debit > 0 ? t("Debit") + ": " + t("priceFormat", { num: unit.debit }) :
                        t("Credit") + ": " + t("priceFormat", { num: - unit.debit })}
                </Typography>
                }
                </Box>
                {/* {Object.keys(unit).length !== 0 && <PaymentRegistration/>} */}
                
                {Object.keys(unit).length !== 0 && <>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell >{t("Invoice Id")}</TableCell>
                            <TableCell >{t("Period")}</TableCell>
                            <TableCell >{t("Total")}</TableCell>
                            <TableCell >{t("Status")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map(invoice => (
                            <TableRow key={invoice.invoiceId}>
                                {}
                                <TableCell >{invoice.invoiceId}</TableCell>
                                <TableCell >{t("dt", { date: new Date(invoice.periodStart) }) + "-" + t("dt", { date: new Date(invoice.periodEnd) })} </TableCell>
                                <TableCell >{t("priceFormat", { num: invoice.total })} </TableCell>
                                <TableCell >{t(invoice.status)} </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Box className={classes.buttons}>
                    <Button style={{alignSelf: "flex-start"}} disabled={this.props.selectedBuildingIndex === undefined || this.props.selectedBuildingIndex === -1} variant="contained" color="primary" onClick={
                        (e) => {
                            e.preventDefault();
                            if (window.confirm(t(`?Settle Unsettled`))) {
                                this.props.dispatch(settleUnsettled(this.props.selectedBuilding))
                            }
                        }}>
                        {t("Settle Unsettled")}
                    </Button>

                        <Button style={{marginLeft: "auto"}} disabled={this.props.selectedBuildingIndex === undefined || this.props.selectedBuildingIndex === -1} variant="contained" color="primary" onClick={
                            (e) => {
                                e.preventDefault();
                                this.setState({ openDialog: true })
                            }}>
                            {t("Register payment")}
                        </Button>
                        </Box>

                    {this.state.openDialog && <PaymentDialog open={this.state.openDialog} invoiceId={defaultPaymentInvoice} amount={defaultPaymentAmount} handleCancel={this.handleCancel} handleUpdate={this.handleUpdate} />}
                        </>}
                    </Box>
                </Grid>

                

                

            </Paper>
            }
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedBuilding: state.selectedBuilding,
        selectedBuildingIndex: state.selectedBuildingIndex,
        selectedUnitIndex: state.selectedUnitIndex,
        bld: state.buildings,
    }
}


export default
    withStyles(styles) (withTranslation()(
        connect(mapStateToProps, null)(
            ManagerInvoicesComponent)));

// export default InvoiceListComponent;
