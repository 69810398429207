import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        gap: "30px",
        justifyContent: "center"
    },
    statsBox: {
        // width: "30px",
        // height: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "10px",
        '& .MuiTypography-root': {
            fontSize: "21px",
            fontWeight: "bold"
        }
    },
    positive: {
        color: "green",
    },
    negative: {
        color: "red"
    }
});

function determineBalanceColor(fullBalance) {
    
    if (fullBalance < 0) {
        return "red";
    }else if (fullBalance > 0) {
        return "green";
    }else {
        return "grey";
    }
}

function BalanceStats({stats}) {

    const { t } = useTranslation();
    const classes = useStyles();

    const balanceColor = determineBalanceColor(stats.fullBalance);

    return(
        <Box className={classes.root}>
        {/* <Typography variant="body2" >
                        {t("Calculated cash balance") + ": " + t("priceFormat", { num: stats.calculatedCashBalance })}
                    </Typography> */}
            <Box className={classes.statsBox}>
                <Typography>Protok novca</Typography>
                <Typography className={classes.positive}>+{t("priceFormat", { num: stats.inflowSum })}</Typography>
                <Typography className={classes.negative}>-{t("priceFormat", { num: stats.buildingPayable + stats.sumFundsPayables })}</Typography>
            </Box>

            <Box className={classes.statsBox}>
                <Typography >{t("Balance")}</Typography>
                <Typography style={{color: balanceColor}}>{t("priceFormat", { num: stats.fullBalance })}</Typography>
            </Box>

            <Box className={classes.statsBox}>
                <Typography>{t("Cash balance")}</Typography>
                <Typography>{t("priceFormat", { num: stats.cashBalance })}</Typography>
            </Box>
        </Box>
    );

}


export default BalanceStats;