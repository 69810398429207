import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from 'react-i18next'

export default function SolutionDialog(props) {

    const [solutionBundle, setSolutionBundle] = React.useState(props.solutionBundle)
    const [inputValid, setInputValid] = useState([false, false]);
    const [errorText, setErrorText] = useState("Required field");
    const { t } = useTranslation();

    useEffect(() => {
        setSolutionBundle(props.solutionBundle)
    }, [props.solutionBundle])

    useEffect(() => {
        setInputValid([!!solutionBundle.solution.name, !!solutionBundle.solution.price]);
    }, [solutionBundle.solution])

    const handleChange = name => event => {
        setSolutionBundle({ ...solutionBundle, solution: { ...solutionBundle.solution, [name]: event.target.value } });
    };

    const validateForm = () => {
        for (let inputValidation of inputValid) {
            if (!inputValidation)
                return false;
        }

        return true;
    }

    return (
        <Dialog open={props.open} PaperProps={{ style: { minWidth: '60vw' } }} >
            <DialogTitle id="form-dialog-title">{t('Solution')}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label= {t("Name")}
                    // type="email"
                    fullWidth
                    value={solutionBundle.solution.name}
                    onChange={handleChange('name')}
                    error={!inputValid[0]}
                    helperText={!inputValid[0] && t(errorText)}
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="price"
                    label={t("Price")}
                    type="number"
                    fullWidth
                    value={solutionBundle.solution.price}
                    onChange={handleChange('price')}
                    error={!inputValid[1]}
                    helperText={!inputValid[1] && t(errorText)}
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label={t("Description")}
                    multiline
                    // type="email"
                    fullWidth
                    rows={5}
                    value={solutionBundle.solution.description}
                    onChange={handleChange('description')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCancel} color="primary">{t("Cancel")}</Button>
                {props.type === "update" && <Button onClick={() => { props.handleUpdate(solutionBundle) }} color="primary">{t("Update")}</Button>}
                {props.type === "add" && <Button disabled={!validateForm()} onClick={() => { props.handleAdd(solutionBundle) }} color="primary">{t("Add")}</Button>}
                {props.type === "delete" && <Button onClick={() => { }} color="primary">{t("Delete")}</Button>}
            </DialogActions>
        </Dialog>
    );
}