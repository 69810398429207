import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';

import { getBuilding } from './../actions/data-manager'

import { Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel } from "@material-ui/core";
import { withTranslation } from 'react-i18next'

class InvoiceListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            link: "",
            isDropbox: true
        }
    }

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        this.props.dispatch(getBuilding(this.props.buildingId))
    }

    handleGenericChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleDropBoxChange = (event) => {
        this.setState({ isDropbox: event.target.value !== "true" })
    }

    render() {
        let { t } = this.props
        let building = (this.props.bld||[])[this.props.selectedBuildingIndex] || {}
        if (this.props.isClientComponent) {
            building = this.props.building || {}
        }
        let docsTmp = building.docs || []
        let allDocs = docsTmp.all || []
        return (
            <Container>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell >{t("Name")}</TableCell>
                            <TableCell >{t("Link")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allDocs.map(doc => (
                            <TableRow key={doc._id}>
                                <TableCell >{doc.name}</TableCell>
                                <TableCell ><a href={doc.link}>link</a> </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedBuilding: state.selectedBuilding,
        selectedBuildingIndex: state.selectedBuildingIndex,
        bld: state.buildings,
        buildingId: state.tenantBuildingId,
        building: state.tenantBuilding
    }
}


export default
    withTranslation()(
        connect(mapStateToProps, null)(
            InvoiceListComponent));
