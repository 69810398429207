import React from 'react';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, TextField, DialogActions, Button } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import nacl from "tweetnacl";

export const KeyDialog = (props) => {
    const { t } = useTranslation();
    const [keyPair] = useState(nacl.sign.keyPair())
    const [isPersisted, setIsPersisted] = useState(true)
    const [savePrivate, setSavePrivate] = useState(true)

    return <Dialog open={props.open}>
        <DialogTitle>{t("Generate key")}</DialogTitle>
        <DialogContent>
            <TextField fullWidth multiline label={t("Private key")} value={props.keyToBase64(keyPair.secretKey)} InputProps={{ readOnly: true }} />
            {/* <IconButton onClick={() => { copy(keyToBase64(keyPair.secretKey)) }} ><FileCopyIcon color={"primary"} /></IconButton> */}
        </DialogContent>
        <DialogContent>
            <TextField fullWidth multiline label={t("Public key")} value={props.keyToBase64(keyPair.publicKey)} InputProps={{ readOnly: true }} />
        </DialogContent>
        <DialogContent>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={savePrivate}
                        value={savePrivate}
                        color="primary"
                        onChange={e => setSavePrivate(!savePrivate)}
                    />
                }
                label={t("Do you want to save private key in local storage?")}
            />

        </DialogContent>
        <DialogContent>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isPersisted}
                        value={isPersisted}
                        color="primary"
                        onChange={e => setIsPersisted(!isPersisted)}
                    />
                }
                label={t("Is private key saved out of application.")}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleCancel} variant="contained" color="primary">{t("Cancel")}</Button>
            <Button disabled={!isPersisted} onClick={e => props.handleOk(props.keyToBase64(keyPair.publicKey), savePrivate ? props.keyToBase64(keyPair.secretKey) : undefined)} variant="contained" color="primary">{t("OK")}</Button>
        </DialogActions>

    </Dialog>
}