import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";
import { Grid } from '@material/react-layout-grid';
import Button from '@material-ui/core/Button';
// import TextField, { Input } from '@material/react-text-field';
// import Checkbox from '@material-ui/core/Checkbox';

import BuildingSelector from './BuildingSelector'
// import {   } from './../actions'
import { postObligation, postInvoiceConfirmation, fetchBuildings,  thunkRequestManager } from './../actions/data-manager'
import { withTranslation } from 'react-i18next'
import MaterialTable from "material-table";

// const INVESTICIONO_CHANGE = "investiciono";
const PER_UNIT_PER_AREA = "per unit/per area";

const SELECTION_NON_GARAGE = "non garage"



class WorkingInvoiceComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            price: 0,
            link: "",
            isPerUnit: false,
            unitPrice: 0,
            accounting: "per group/per unit",
            investmentFund: 0,
            // funds: [{ name: "BRISIII ME IZ STATE" }]

        }
    }

    handleGenericChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handlePerUnitChange = (event) => {
        this.setState({ isPerUnit: event.target.value !== "true" })
    }


    componentDidMount() {
        //TODO: handle connection errors
        this.props.dispatch(fetchBuildings())
    }

    render() {




        let { t } = this.props

        //NOTE: !!! Duplicated code !!!
        const columnsFund = [
            { title: t('name'), field: "name" },
            { title: t('price'), field: "price" },
            {
                title: t('accounting'), field: "accounting"
                , lookup: {
                    "per group/per unit": "Ukupno",
                    "per unit/per unit": "Po jedinici",
                    [PER_UNIT_PER_AREA]: "Po kvadratu"
                },
            },
            {
                title: t('selection'), field: "selection"
                , lookup: {
                    [SELECTION_NON_GARAGE]: "Bez garaža"
                }
            },
            { title: t('balance'), field: "balance", initialEditValue: 0 },
            { title: t('receivable'), field: "receivable", editable: "never" }
        ]

        let { name, price/*, link*/, periodStart, periodEnd, investmentFund } = this.state
        // let { selectedBuilding } = this.props
        let finance =  (this.props.bld[this.props.selectedIndex] ? this.props.bld[this.props.selectedIndex]: {}).finance || {}
        let fundMap = (finance.fund||[]).reduce((a,x)=> ({...a, [x._id]:x.name}),{})

        let items = this.props.bld[this.props.selectedIndex] ? this.props.bld[this.props.selectedIndex].finance.workingPayable : []
        let items2 = this.props.bld[this.props.selectedIndex] ? this.props.bld[this.props.selectedIndex].finance.workingFund : []
        items2 = items2.map(x=>({...x, name:fundMap[x._id]}))
        items = [...items, ...items2]
        let { isPerUnit } = this.state
        let funds = this.props.bld[this.props.selectedIndex] ? this.props.bld[this.props.selectedIndex].finance.fund : []
        return (
            <Container>
                <BuildingSelector>
                </BuildingSelector>
                {this.props.selectedBuilding && <>
                    <Grid>

                        <MaterialTable
                            title={t('Funds')}
                            options={{ search: false, paging: false }}
                            columns={columnsFund}
                            data={funds}
                            editable={{
                                isEditHidden: d => true,
                                onRowAdd: newFund => new Promise((resolve, reject) => {
                                    // this.setState({ funds: [...this.state.funds, newFund] })
                                    if (!newFund.accounting || !newFund.selection){
                                        reject("Not set properly")
                                        return;
                                    }
                                    this.props.dispatch(thunkRequestManager(`/finance/fund`,
                                        { fund: newFund, buildingId: this.props.selectedBuilding },
                                        (err, res) => {
                                            if (err) {
                                                alert(err.errorMessage)
                                            } else {
                                                resolve()
                                            }
                                        }
                                    ))
                                }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                    }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        this.props.dispatch(thunkRequestManager(`/finance/fund`,
                                            { fund: oldData, buildingId: this.props.selectedBuilding },
                                            (err, res) => {
                                                if (err) {
                                                    alert(err.errorMessage)
                                                } else {
                                                    resolve()
                                                }
                                            }
                                            , "DELETE"))
                                    }),

                            }}
                        />

                        <br />
                        <Button variant="contained" color="primary" onClick={
                            (e) => {
                                e.preventDefault();
                                this.props.dispatch(thunkRequestManager(`/finance/begin-invoicing`,
                                    { buildingId: this.props.selectedBuilding },
                                    (err, res) => {
                                        if (err) {
                                            alert(err.errorMessage)
                                        } else {
                                            // resolve() NOTIIFY USER
                                        }
                                    }
                                ))
                            }}>
                            {t("Prepare invoice")}
                        </Button>

                        <span style={{ display: "inline-block", width: "5px" }} />


                        <Button variant="contained" color="primary" onClick={
                            (e) => {
                                e.preventDefault();
                                this.props.dispatch(thunkRequestManager(`/finance/reset-invoicing`,
                                    { buildingId: this.props.selectedBuilding },
                                    (err, res) => {
                                        if (err) {
                                            alert(err.errorMessage)
                                        } else {
                                            // resolve() NOTIFY USER
                                        }
                                    }
                                ))
                            }}>
                            {t("Reset invoice")}
                        </Button>


                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell >{t("Name")}</TableCell>
                                    {/* <TableCell >{t("Link")}</TableCell> */}
                                    <TableCell >{t("Price")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    items.map(item => (

                                        <TableRow key={item._id}>
                                            <TableCell >{item.name}</TableCell>
                                            {/* <TableCell >{item.link}</TableCell> */}
                                            <TableCell >{item.price}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>



                    </Grid>
                    <Grid>
                        <TextField label={t("Name")}
                            name="name"
                            // type="text"
                            value={name}
                            onChange={this.handleGenericChange}
                        />
                        {/* </Grid>
                <Grid> */}
                        {/* <TextField label={t("Link")}
                            name="link"
                            // type="text"
                            value={link}
                            onChange={this.handleGenericChange}
                        /> */}
                        <TextField label={isPerUnit ? t("Unit price") : t("Price")}
                            name="price"
                            type="Number"
                            value={price}
                            onChange={this.handleGenericChange}
                        />
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    name="isPerUnit"
                                    checked={isPerUnit}
                                    value={isPerUnit}
                                    onChange={this.handlePerUnitChange}
                                />
                            }
                            label={t("Is unit price")}
                        /> */}

                        <FormControl>
                            <InputLabel htmlFor="pricing-type"   >{t("Pricing type")}</InputLabel>
                            <Select
                                name="pricing-type"
                                // labelId="user-select"
                                // style={{ minWidth: 180 }}
                                value={this.state.accounting}
                                onChange={(event) => this.setState({ accounting: event.target.value })}
                            >
                                <MenuItem value={"per group/per unit"} >{t("per group/per unit")}</MenuItem>
                                {/* <MenuItem value={"per group/per area"}>{t("Group/per area")}</MenuItem> */}
                                <MenuItem value={"per unit/per unit"}>{t("per unit/per unit")}</MenuItem>
                                {/* <MenuItem value={"per unit/per area"}>{t("Unit/per area")}</MenuItem>
                                <MenuItem value={"investiciono"}>{t("Investiciono")}</MenuItem> */}
                                )
                            } */}
                            </Select>
                        </FormControl>


                        {/* <FormControl>
                            <InputLabel htmlFor="item-type"   >Item type</InputLabel>
                            <Select
                                name="user"
                                labelId="user-select"
                                style={{ minWidth: 180 }}
                            // value={selectedUser}
                            // onChange={(event) => setSelectedUser(event.target.value)}
                            >
                                <MenuItem>CURRENT</MenuItem>
                                <MenuItem>Per Unit (UNIT: NOT GARAGE)</MenuItem>
                                <MenuItem>Per Group (UNIT: NOT GARAGE)</MenuItem>
                                <MenuItem>_V2_</MenuItem>
                                <MenuItem>Per Area</MenuItem>
                                <MenuItem>_V3_</MenuItem>
                                <MenuItem>_UNITSELECTION_PREDEFINED-NON-GARAGE_</MenuItem>
                                <MenuItem>_PRICING_TOTAL/PARTIAL_</MenuItem>
                                <MenuItem>_PRICING_PER-AREA/PER-UNIT_</MenuItem>
                                <MenuItem>_IS_REPEATING_</MenuItem>
                                <MenuItem>_V3_RPICING</MenuItem>
                                <MenuItem>_PRICING_TOTAL/SPLIT-UNIT_</MenuItem>
                                <MenuItem>_PRICING_TOTAL/SPLIT-AREA_</MenuItem>
                                <MenuItem>_PRICING_PARTIAL/UNIT_</MenuItem>
                                <MenuItem>_PRICING_PARTIAL/AREA_</MenuItem>
                                <MenuItem>_PRICING_SHOW-TOTAL_</MenuItem>
                                <MenuItem>_V4_</MenuItem>
                                <MenuItem>_UNITSELECTION-BOX-GARAGE/FLAT/OTHER_</MenuItem>
                                <MenuItem>_UNITSELECTION_LIST_</MenuItem> */}
                        {/* {
                            props.users && Object.keys(props.users).map(
                                k => <MenuItem value={props.users[k].user} key={props.users[k].user} >{props.users[k].firstName + " " + props.users[k].lastName}</MenuItem>
                                // <MenuItem value={10}>Ten</MenuItem> 
                                // <Option value={props.users[k].user} key={props.users[k].user} >{props.users[k].firstName + " " + props.users[k].lastName}</Option>
                            )
                        } */}
                        {/* </Select>
                        </FormControl> */}



                        <Button variant="contained" color="primary" disabled={!this.state.name} onClick={
                            (e) => {
                                e.preventDefault();
                                // let accounting = isPerUnit ? "per unit" : "per group"
                                this.props.dispatch(postObligation(this.props.selectedBuilding, this.state.name, this.state.link, this.state.price, this.state.accounting,
                                    () => this.setState({ name: "", price: 0, link: "", isPerUnit: false })))
                            }}>
                            {t("Add Item")}
                        </Button>
                    </Grid>
                    <br />

                    <Grid>
                        <TextField InputLabelProps={{ shrink: true }} label={t("Period Start")}
                            name="periodStart"
                            // type="date"
                            type="date"
                            value={periodStart}
                            onChange={this.handleGenericChange}
                        />
                        <TextField InputLabelProps={{ shrink: true }} label={t("Period End")}
                            name="periodEnd"
                            type="date"
                            value={periodEnd}
                            onChange={this.handleGenericChange}
                        />
                        <Button variant="contained" color="primary" disabled={!items.length || !periodStart || !periodEnd} onClick={
                            (e) => {
                                e.preventDefault();
                                this.props.dispatch(postInvoiceConfirmation(this.props.selectedBuilding, this.state.periodStart, this.state.periodEnd))
                            }}>
                            {t("Confirm Invoice")}
                        </Button>

                    </Grid>
                </>}
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        bld: state.buildings,
        selectedBuilding: state.selectedBuilding,
        selectedIndex: state.selectedBuildingIndex
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, null)(
            WorkingInvoiceComponent));
