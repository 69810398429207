import React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";


export default function CommonTable(props) {
    return <Table>
        <TableHead>
            <TableRow>
                {props.header.map(name => (
                    <TableCell key={name}>{name}</TableCell>
                ))}
            </TableRow>
        </TableHead>
        <TableBody>
            {props.data.map(entry => (
                <TableRow key={entry[props.keyProperty]}>
                    {props.fields.map((field, ix) => ((field instanceof Function) ?
                        <TableCell key={ix} >{field(entry)}</TableCell>
                        :
                        <TableCell key={field} >{entry[field]}</TableCell>
                    )
                    )}
                    {
                        props.createCustomCells && props.createCustomCells(entry).map((component, ix) => <TableCell key={ix}>{component}</TableCell>)
                    }
                </TableRow>
            ))}
        </TableBody>
    </Table >
}