import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from '@material/react-layout-grid';
import Container from '@material-ui/core/Container';

import { fetchBuildings, postOutflow, thunkRequestManager } from './../actions/data-manager'

import { withTranslation } from "react-i18next";
import { useTranslation } from 'react-i18next';

import { ObligationList } from "./ObligationList";
import BuildingSelector from "./BuildingSelector";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from "@material-ui/core";

import MaterialTable from "material-table";
const PER_UNIT_PER_AREA = "per unit/per area";
const SELECTION_NON_GARAGE = "non garage"

function PayFromFundDialog(props) {
    const [item, setItem] = React.useState("")
    const [amount, setAmount] = React.useState("")

    const { t } = useTranslation();
    return <Dialog open={props.open}>
        <DialogTitle>{t("Pay from fund")}</DialogTitle>
        <DialogContent>
            <TextField label={t("Fund")} value={props.fund.name} InputProps={{ readOnly: true }} />
        </DialogContent>
        <DialogContent>
            <TextField label={t("Item")} value={item} onChange={(e) => setItem(e.target.value)} />
        </DialogContent>
        <DialogContent>
            <TextField label={t("Price")} value={amount} onChange={(e) => setAmount(e.target.value)} />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleCancel} color="primary">Cancel</Button>
            <Button onClick={e => props.handlePay({ fundId: props.fund._id, reason: item, amount })} color="primary">Pay</Button>
        </DialogActions>
    </Dialog>
}


class MangerBuildingFinance extends Component {

    state = { showDialog: false, currentFund: null }

    componentDidMount() {
        //TODO: handle connection errors
        //TODO: consider using this from state
        this.props.dispatch(fetchBuildings())
        //TODO: implment different call for Manager and Tenant
    }



    actionRenderCallback(payable) {
        //TODO: add translation
        // let {t} = this.props
        return <>
            <Button size="small" color="primary" variant="contained" disabled={payable.status === "paid"} onClick={e => {
                if (window.confirm('Pay the item: ' + payable.name)) {
                    this.dispatch(postOutflow(this.building._id, payable._id, payable.price, payable.name))
                }
            }}>
                Pay
        </Button>
        </>
    }

    render() {

        let { t } = this.props

        //NOTE: !!! Duplicated code !!!
        const columnsFund = [
            { title: t('name'), field: "name" },
            { title: t('price'), field: "price" },
            {
                title: t('accounting'), field: "accounting"
                , lookup: {
                    "per group/per unit": "Ukupno",
                    "per unit/per unit": "Po jedinici",
                    [PER_UNIT_PER_AREA]: "Po kvadratu"
                },
            },
            {
                title: t('selection'), field: "selection"
                , lookup: {
                    [SELECTION_NON_GARAGE]: "Bez garaža"
                }
            },
            { title: t('balance'), field: "balance", initialEditValue: 0 },
            { title: t('spent'), field: "spent", initialEditValue: 0 },
            { title: t('receivable'), field: "receivable", editable: "never" }
        ]

        let funds = this.props.building.finance ? this.props.building.finance.fund : []

        return <>
            <Grid>
                <BuildingSelector />

                {this.state.showDialog &&
                    <PayFromFundDialog
                        open={this.state.showDialog}
                        handleCancel={e => this.setState({ showDialog: false })}
                        handlePay={v => {
                            this.props.dispatch(thunkRequestManager(`/finance/fund-outflow`,
                                { buildingId: this.props.building._id, ...v },
                                (err, res) => {
                                    if (err) {
                                        alert(err.errorMessage)
                                    } else {
                                        // this.setState({ showDialog: false })
                                    }
                                }
                            ))
                            this.setState({ showDialog: false })
                        }}
                        fund={this.state.currentFund}
                    />}

                <Container>
                    <Grid>
                        <MaterialTable
                            title={t('Funds')}
                            options={{ search: false, paging: false }}
                            columns={columnsFund}
                            data={funds}
                            actions={[
                                {
                                    icon: 'euro',
                                    tooltip: t('Pay from fund'),
                                    onClick: (event, rowData) => {
                                        this.setState({ currentFund: rowData, showDialog: true })
                                    }
                                }
                            ]}
                        />
                    </Grid>
                </Container>

                <ObligationList {...this.props} action={this.actionRenderCallback} />
            </Grid>
        </>
    }
}

function mapStateToProps(state) {
    return {
        building: state.buildings[state.selectedBuildingIndex] || {}
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, null)(
            MangerBuildingFinance));