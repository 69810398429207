import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en';
import sr from '../locale/sr';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en,
            sr
        },
        fallbackLng: 'en',
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            format: function (data, format, lng) {
                // if(value instanceof Date) return moment(value).format(format);
                // return value;

                if (format === 'intlDate')
                    return new Intl.DateTimeFormat(lng).format(data); // -> "12/20/2012" if run in en-US locale with time zone America/Los_Angeles

                if (format === 'DD/MM/YYYY')
                    return data.getDate() + "." + (data.getMonth() + 1) + "." + data.getFullYear()

                if (format === 'num2format')
                    return new Intl.NumberFormat(lng, {maximumFractionDigits:2, minimumFractionDigits:2}).format(data)
            },
            escapeValue: false
            // formatSeparator: ',',
        },
        lng: "sr",
        react: {
            wait: true,
        },
    });

export default i18n;
