import React, { Component } from "react";
import { connect } from "react-redux";
// import Select, { Option } from '@material/react-select';
import { changeSelectedBuilding } from './../actions'
import { withTranslation } from 'react-i18next'
import { InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";


class BuildingSelectorComponent extends Component {

    render() {
        let { t } = this.props
        let { selectedBuilding } = this.props
        return (
                <FormControl style={{minWidth: "75px", marginBottom: "20px"}}>
                <InputLabel htmlFor="building-select"   >{t("Building Name")}</InputLabel>
                <Select
                    name="building"
                    labelid="building-select"
                    value={selectedBuilding || ""}
                    onChange={(event) => {
                        localStorage.setItem("selectedBuildingId", event.target.value)
                        const ind = this.props.buildingNames.findIndex(x=>x.id === event.target.value) + 1
                        localStorage.setItem("selectedBuildingIndex", ind)
                        this.props.changeSelectedBuilding(event.target.value, ind)
                    }}
                    MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                    {/*TODO: verify if undefined is ok value*/}
                    <MenuItem value={""} key={""} ></MenuItem>
                    {
                        this.props.buildingNames.map(building => (
                            // <Option value={building.id} key={building.id} > {building.name}</Option>
                            <MenuItem value={building.id} key={building.id} > {building.name}</MenuItem>
                        ))

                    }
                </Select >
                </FormControl>   
                
                 /* { <Select
                label={t("Building Name")}
                name="building"
                value={selectedBuilding}
                onChange={(event) => {
                    localStorage.setItem("selectedBuildingId", event.target.value)
                    localStorage.setItem("selectedBuildingIndex", event.target.selectedIndex)
                    this.props.changeSelectedBuilding(event.target.value, event.target.selectedIndex)
                }}
            >
                TODO: verify if undefined is ok value
                <Option value={undefined} key={undefined} ></Option>
                {
                    this.props.buildingNames.map(building => (
                        <Option value={building.id} key={building.id} >{building.name}</Option>
                    ))
                }
            </Select> 
*/
        );
    }
}



function mapStateToProps(state) {
    return {
        selectedBuilding: state.selectedBuilding,
        //TODO: check if map will rerender
        buildingNames: (state.buildings || []).map(building => { return { name: building.name, id: building._id } })
    }
}

export default
    withTranslation()(
        connect(mapStateToProps, { changeSelectedBuilding })(
            BuildingSelectorComponent));
