import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { Grid, Row, Cell } from '@material/react-layout-grid';
// import Button from '@material-ui/core/Button';
// import TextField, { Input } from '@material/react-text-field';

// import BuildingSelector from './BuildingSelector'
import { fetchBuildings } from './../actions/data-manager'
// import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import Card, {
    CardPrimaryContent,
    // CardContent,
    // CardMedia,
    // CardActions,
    // CardActionButtons,
    // CardActionIcons
} from "@material/react-card";
import '@material/react-card/dist/card.css';
// @import "@material/card/mdc-card";


class NotificationListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // title: "",
            // description: "",
            notificationIndex: 0
        }
        this.timer = setInterval(() => {
            this.props.dispatch(fetchBuildings())

            //TODO extract this into state
            let building = this.props.bld[this.props.match.params.index] || {}
            let notifications = building.notifications || []
            let notificationIndex = this.state.notificationIndex + 1
            if (notificationIndex >= notifications.length) {
                notificationIndex = 0
            }
            this.setState({
                notificationIndex
            })

        }, 3500)
        //   console.log("start")
    }

    handleGenericChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {
        let building = this.props.bld[this.props.match.params.index] || {}
        let notifications = building.notifications || []
        let notificationIndex = this.state.notificationIndex
        // Detect if the notifications were removed
        if (notifications.length <= notificationIndex) {
            notificationIndex = 0
        }
        let focusedNotification = notifications.length ? notifications[notificationIndex] : {}

        // let { title } = this.state
        // let { description } = this.state

        var cardStyle = {
            // display: 'block',
            // width: '20vw',
            // transitionDuration: '1.3s',
            height: '11vw'
        }

        return (
            <Container>
                {/* <Grid>
                    <BuildingSelector />
                </Grid> */}
                <Grid>
                    <Card outlined>
                        <CardPrimaryContent>
                            <h1>
                                <font size="+6">
                                    {focusedNotification.title}
                                </font>
                            </h1>
                            <p>
                                <font size="+2">
                                    {focusedNotification.description}
                                </font>
                            </p>
                        </CardPrimaryContent>
                    </Card>
                </Grid>
                <Grid >
                    <Row>
                        {notifications.map(notification => (
                            <Cell key={notification._id}>
                                <Card outlined style={cardStyle}>
                                    <CardPrimaryContent>
                                        <h1>
                                            <font size="+0">
                                                {notification.title}
                                            </font>
                                        </h1>
                                        <p>
                                            <font size="+0">
                                                {notification.description}
                                            </font>
                                        </p>
                                    </CardPrimaryContent>
                                </Card>
                            </Cell>
                        ))}
                    </Row>
                </Grid>

            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        bld: state.buildings
    }
}


export default connect(mapStateToProps, null)(NotificationListComponent);
