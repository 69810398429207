import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";


import { postLogin } from './../actions/data-manager'
import { genericAction, TOKEN_RECEIVED, TOKEN_RECEIVED_MANAGER } from '../actions';
import { JWT_MANAGER, JWT_TENANT } from '../actions/network-manager';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://mojspart.com/">
                MojSprat
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// const useStyles = makeStyles(theme => ({
//     '@global': {
//         body: {
//             backgroundColor: theme.palette.common.white,
//         },
//     },
//     paper: {
//         marginTop: theme.spacing(8),
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//     },
//     avatar: {
//         margin: theme.spacing(1),
//         backgroundColor: theme.palette.secondary.main,
//     },
//     form: {
//         width: '100%', // Fix IE 11 issue.
//         marginTop: theme.spacing(1),
//     },
//     submit: {
//         margin: theme.spacing(3, 0, 2),
//     },
// }));

// const styles = {
//     '@global': {
//         body: {
//             backgroundColor: theme.palette.common.white,
//         },
//     },
//     paper: {
//         marginTop: theme.spacing(8),
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//     },
//     avatar: {
//         margin: theme.spacing(1),
//         backgroundColor: theme.palette.secondary.main,
//     },
//     form: {
//         width: '100%', // Fix IE 11 issue.
//         marginTop: theme.spacing(1),
//     },
//     submit: {
//         margin: theme.spacing(3, 0, 2),
//     },
// }

let defaultUser = ""
let defaultManager = ""
let defaultPass = ""
if (process.env.REACT_APP_USE_DEV) {
    defaultUser = "Markovic"
    defaultManager = "milos"
    defaultPass = "zrm"
}


class Login extends Component {
    state = {
        username: this.props.toManager ? defaultManager : defaultUser,
        password: defaultPass,
        isError: false,
        helperText: "",
        redirect: false
    }


    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.userLoginFetch(this.state)
    }

    render() {
        //TODO: handle use styles
        // let classes = useStyles();
        let classes = {}
        let { t } = this.props

        // console.log("render LOGIN, redirect: " + this.state.redirect)
        if (this.state.redirect) {
            if (this.props.toManager) {
                return <Redirect to="/manager/buildings" />
            } else {
                return <Redirect to="/tenant/notifications" />
            }
        }

        return (

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("Sign in")}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("Username")}
                            name="username"
                            autoComplete="email"
                            autoFocus
                            value={this.state.username}
                            onChange={this.handleChange}
                            error={this.state.isError}
                            helperText={this.state.helperText}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("Password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            error={this.state.isError}
                            helperText={this.state.helperText}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={
                                (e) => {
                                    e.preventDefault()
                                    let dspRes = this.props.dispatch(postLogin(
                                        this.state.username,
                                        this.state.password,
                                        this.props.toManager,
                                        (err, res, dsp) => {
                                            if (err) {
                                                console.log("Login error");
                                                console.log(err);
                                                localStorage.removeItem(this.props.toManager ? JWT_MANAGER : JWT_TENANT)
                                                this.setState({ isError: true,/* helperText:err.toString()*/ })
                                                return;
                                            }
                                            localStorage.setItem(this.props.toManager ? JWT_MANAGER : JWT_TENANT, res)
                                            let tokenActionType = this.props.toManager ? TOKEN_RECEIVED_MANAGER : TOKEN_RECEIVED
                                            this.props.dispatch(genericAction(tokenActionType, res))
                                            //NOTE: Login is not mapped to state (notification), so next lines are not necessary
                                            //TODO: consider moving next line to dsp.then(this.setState()); 
                                            // pass result in thunk in thunkRequestBase
                                            this.setState({ redirect: true, isError: false })
                                        }))
                                        // console.log("dspRes")
                                        // console.log(dspRes)

                                }}
                        >
                            {t("Sign in")}
                        </Button>
                        {/* <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid> */}
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        );
    }
}

export default withTranslation()(
    connect(null, null)(Login));